* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  margin: 0;
  padding: 0;
}

/**  dashboard TodayjobBoxToggle start from here  **/

.allphotosstatus img {
  width: 100%;
  height: 100%;

  border-radius: 5px;
}

.job-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 5px;
  /* padding: 10px 9px;
  gap: 2px; */
}

.customer-name {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: var(--font-size-24);
  line-height: 42px;
  font-weight: var(--font-weight-6);
  font-family: inherit;
  color: #2d2d2d;
}

.customer-name:hover {
  color: var(--color-lightblue);
}

.customername {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.install-date {
  flex: 1;
  position: relative;

  font-size: var(--font-size-16);
}

.customerdata,
.installdate {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}

.installdate {
  flex-direction: row;
  align-items: center;
  font-size: var(--font-size-14);
  color: #484c4f;
}

.customerdata {
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.allphotosstatus-child {
  position: relative;
  width: 14.54px;
  height: 23.39px;
}

.div {
  position: relative;
  letter-spacing: -0.06em;
  line-height: 10px;
  font-weight: var(--font-weight-6);
}

.allphotosstatus,
.noofphotos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.allphotosstatus {
  border-radius: 5px;
  background-color: var(--color-aliceblue);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 5px;
}

/* .allphotosstatus p{
  border: 1px solid;
  width: 35px;
  height: 35px;
} */

.panelscanstatus-child,
.vector-icon {
  position: relative;
  width: 30px;
  height: 30px;
}

.vector-icon {
  width: 23px;
}

.designerstatus-child {
  position: relative;
  width: 22px;
  height: 30.43px;
}

.inverterscanstatus-child,
.layer-21-icon {
  position: relative;
  width: 25px;
  height: 29.98px;
}

.layer-21-icon {
  width: 26.8px;
  height: 28px;
}

.customersignstatus-child {
  position: relative;
  width: 29.53px;
  height: 27px;
}

.retailerdeclarationstatus-child {
  position: relative;
  width: 31px;
  height: 29.01px;
}

.startselfiestatus-child {
  position: relative;
  width: 30px;
  height: 31.16px;
}

.startselfiestatus {
  border-radius: 5px;
  background-color: var(--color-aliceblue);
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.endselfiestatus-child {
  position: relative;
  width: 29.04px;
  height: 30.16px;
}

.spv {
  position: relative;
  font-weight: var(--font-weight-6);
  color: #000;
}

.spv:hover {
  color: #000;
}

.jobstatus,
.spvstatus {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .spvstatus {
  border-radius: var(--br-8xs);
  background-color: var(--color-aliceblue);
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: center;
  text-align: left;

  color: #464646;
} */

.jobstatus {
  align-self: stretch;
  flex-wrap: wrap;
  text-align: center;
  font-size: var(--font-size-10);
  width: 200px;
  gap: 10px;
}

@media (min-width: 1701px) {
  /* .system-img {
  display: flex;
} */
  .system-img {
    /* width: 100%; */
  }
  .panel-details {
    width: 50%;
    text-align: start;
  }
  .jobstatus {
  }
  .main-price {
    width: 75% !important;
  }
  .live-price {
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .priceblue-progress {
    display: flex;
    align-items: center;
    width: 11% !important;
  }
  .text-white {
    font-size: 1.1rem !important;
    padding: 17px !important;
  }

  .system-info {
    width: 40% !important;
    display: flex;
    flex-direction: column;

    /* justify-content: space-around; */
  }

  .dash-address {
    margin: 0 10px 0 0px;
    width: 20%;
  }
  .input-search {
    width: 95%;
  }
  .system-all {
    /* justify-content: space-between !important; */
    flex-direction: row !important;
  }
  .buttonBox {
    width: 32%;
  }
  .button {
    width: 100% !important;
  }

  .stc-count {
    /* width: 0%; */
    width: 153px !important;
  }
  .price-data li {
    padding: 10px 15px !important;
    color: rgba(44, 58, 100, 1);
    font-size: 10px !important;
    font-weight: 400;
    width: max-content;
    align-items: center;
  }
  .price-data {
    display: flex;
    align-items: center;
    padding: 0 2%;
    text-align: center;
    /* justify-content: space-between; */
  }

  .STCPriceSection {
    width: 40%;
    /* margin: 10px 0px; */
    padding: 12px;
    flex-direction: row !important;
  }
  .PriceDataText {
    color: rgba(44, 58, 100, 1);
    font-size: 15px !important;
    font-weight: 500;
    margin-right: 12px;
  }
  .CreateJocontainer {
    width: 100%;
  }
  .ImageUploadSectionsPVJobs {
    width: 100% !important;
    align-items: start;
  }
  .panel-photobg {
    width: 100% !important;
  }
  .main-part-ratailer {
    width: 88% !important;
    /* margin-left: 13%; */
  }
  .TeamDetailsRowData {
    gap: 4% !important;
    /* padding-left: 4% !important; */
  }
  .CreateJobContainer {
    width: 88vw !important;
  }
  .left-panel {
    width: 12vw !important;
  }
  .InformationFormDiv {
    /* width: 84vw !important; */
  }
  .live-Price-main-section {
    /* margin-left: 13% !important; */
    width: 83vw;
  }
  button.btn-createjob {
    width: 80% !important;
  }
  .certificate-text {
    width: 150px !important;
    height: 250px;
  }
  .sideBarMenu {
    height: 88vh !important;
  }
  .dashboard-logo {
    /* height: 12vh !important; */
  }
  .dash-text {
    width: 10% !important;
  }
  .buttons-container {
    /* justify-content: center !important; */
    margin-right: 8px !important;
    gap: 20px;
  }
  .traderDashButtons {
    width: 180px !important;
  }
  .search-container {
    width: 90% !important;
    min-width: 100%;
    min-height: 52px;
    margin: 15px 0px 15px 20px;
  }
  
  .team-data-actions {
    width: 16% !important;
   
  }
  .CreatJobDetailsForm{
    width: 88% !important;
  }

}
.price-DataDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.user-address {
  width: 55% !important;
  text-wrap: wrap;
  /* margin-bottom:2% !important ; */
}
.buttonContainer {
  justify-content: center !important;
  margin-top: 4%;
}
.rightbar {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 30px;
  /* border: 1px solid; */
}

@media  (min-width: 750px) and (max-width: 924px) and (orientation:portrait){
  .DetailsOfOwner {
    gap: 4px;
    width: 30% !important;
  }
  .user-text {
    width: 84% !important;
    color: rgba(44, 58, 100, 1);
    line-height: 17px;
    font-size: 10px !important;
    overflow-wrap: break-word !important;
  }
  .user-name {
    font-size: 12px !important;
  }
  .main-stc.text-stc {
    width: 25% !important;
  }
  .dash-text {
    width: 32% !important;
  }
  .inform-text {
    font-size: 11px !important;
  }
  .stc-count {
    /* width: 0%; */
    width: 70% !important;
    font-size: 14px !important;
    height: 30px !important;
  }
  .traderDashButtons {
    width: 110px !important;
    height: 33px !important;
    font-size: 10px !important;
  }
  button.btn-createjob {
    width: 400px !important;
    height: 20px !important;
    font-size: 11px !important;
  }

  .popupsystem-all {
    flex-wrap: wrap !important;
  }
  .text-notes {
    margin: 10px 0px !important;
    font-size: 11px !important;

    padding: 3px 5px !important;
  }
  .address-type {
    width: 30% !important;
    font-size: 11px !important;
  }
  .email-id-owner {
    font-size: 11px !important;
  }
  .emailSection {
    width: 86% !important;
    text-wrap: wrap;
  }

  .input-search {
    width: 94% !important;
  }
  .search-filter-section {
    width: 88% !important;
    display: flex;
    flex-direction: row;
    /* gap:2%; */
    align-items: center;
  }
  .main-part-ratailer {
    /* margin-left: 17%; */
    width: 83vw !important;
  }
  .CreateJobContainer {
    /* width: 56vw !important; */
    width: 99% !important;
  }
  .InformationFormDiv{
    width: 98% !important;
  }
  .border-bottom {
    width: 94% !important;
  }
  .instructions-input {
    font-size: 10px;
    width: 242px !important;
    line-height: 13px;
    height: 280px;
    margin-left: 0 !important;
  }
  .panel-main-bg{
    margin-bottom: 0 !important;
  }
  .retailer-input {
    width: 244px !important;
    height: 52px;
    line-height: 13px;
  }
  .current-input {
    width: 244px !important;
   
  }
  .map-section {
    margin: 0px 0 0 10px !important;
    width: 321px !important;
    height: 196px !important;
    overflow-x: hidden !important;
  }
  .attributes-input {
    margin: 15px 10px 0 0px;
    padding: 10px;
    width: 244px;
   
  }
  .team-section{
    width: 100%;
  }
  .TeamDetailsRowData {
    flex-wrap: nowrap !important;
    gap:2px !important;
    
  }

  .team-data-main {
    width: 181px !important;
  }
  .team-details {
    flex-direction: column !important;
  }
  .team-data-role {
    width: 166px !important;
  }
 
  .TeamsTableHeading{
    width: 100% !important;
  }
  
  .dashboard-logo {
    /* height: 1vh !important; */
  }
  .Logo-image-sideMenu{
    width: 100%;
  }
  .left-part {
    /* width: 100% !important; */
    height: 46px !important;
    gap: 15px;
  }
  
  /* Hover state when it's not active */
  .left-part:not(.active):hover {
    background: rgb(21 67 176);
    /* slightly transparent blue */
    color: white;
   
  }
  .left-text {
    font-size: 10px !important;
    font-weight: 400;
    width: 100% !important;
    
  }
  .left-img{
    margin: 10px !important;
  }
  .resetpassword-container{
    /* width: 80% !important; */
    /* max-width:60% !important; */
    }
  
}


.editjob-child {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-lightblue);
  width: 60px;
  height: 60px;
  z-index: 0;
}

.editjob-item {
  position: absolute;
  margin: 0 !important;
  top: 17px;
  left: 16.95px;
  width: 26px;
  height: 26px;
  z-index: 1;
}

.editjob {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  gap: 10px;
  left: 5px;
  top: 0px;
}

.river-lane-port {
  margin: 0;
}

.river-lane-port-mannum-container {
  width: 200px;
  height: 60px;
  align-self: stretch;
  position: relative;
  font-size: var(--font-size-16);
  line-height: 24px;
  font-family: Poppins;
  color: var(--color-dimgray);
}

.addressdata {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.stcheading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.amount {
  position: relative;
  line-height: 24px;
  font-weight: var(--font-weight-6);
}

.stcrate {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.kw {
  position: relative;
}

.syssize {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.noofstc,
.stcdata,
.syssize {
  justify-content: flex-start;
}

.noofstc {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
}

.stcdata {
  align-self: stretch;
  align-items: flex-start;
  font-size: var(--font-size-16);
  color: var(--color-dimgray);
  font-family: Poppins;
}

.leftbar,
.sidedata,
.stcdata {
  display: flex;
  flex-direction: column;
}

.sidedata {
  align-self: stretch;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 15px; */
}

.leftbar {
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  gap: 5px;
  font-size: var(--font-size-22);
  font-family: Work Sans;
  /* border: 1px solid; */
}

.jobdatastatus {
  text-decoration: none;
  /* position: relative; */
  border-radius: 40px;
  background-color: var(--color-lightgrey);
  gap: 5px;
  /* border: 1px solid; */
  /* width: 100%; */
  display: flex;

  flex-wrap: wrap;
  /* align-items: center; */
  justify-content: flex-start;
  padding: 15px 15px 15px 15px;

  border: 1px solid #acb4b4;

  /* gap: var(--gap-xl); */
  text-align: left;
  font-size: var(--font-size-38);
  color: var(--color-black);
  font-family: Poppins;
  width: 455px;
}

.jobdatastatus:hover .customer-name,
.jobdatastatus:hover .spv {
  color: var(--color-lightblue);
  transition: color 0.5s;
}

@media screen and (max-width: 1370px) {
  .jobdatastatus {
    width: 410px;
    /* border: 1px solid red; */
  }
}

@media screen and (max-width: 1300px) {
  .jobdatastatus {
    width: 380px;
    /* border: 1px solid red; */
  }
}

/** TodayJobBoxToggle.jsx End here **/

/**  JobDetails.jsx  start from here**/

.jobDetails-parent-container {
  display: flex;
  /* justify-content: center; */
  padding: 30px;
}

.jobDetails-section-one-container {
  /* flex-grow: 8; */
  margin-right: 15px;
  /* box-shadow: 0px 4px 44px 0px #0000001a; */
  /* padding: 30px 5px 30px 10px; */
  border-radius: 40px;
  flex: 1;
}

.jobDetails-section-two-container {
  /* padding: 30px 15px 0px 0px; */
  flex-grow: 1;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 30px;
}

.jobDetails-lists-container {
  display: flex;
  margin: 20px 0px;
  font-size: var(--font-size-16);
  gap: 25px;
}

.jobDetails-lists-container button {
  text-decoration: none;
  color: #000000;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  border: none;
  /* width: 200px; */
  height: 60px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #d3d3d3;
  cursor: pointer;
  flex: 1;
}

.jobDetails-aside-container {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

#customeractive.active {
  box-shadow: 0px 4px 14px 0px #00000026;
  background-color: var(--color-lightgrey);
  border: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-5);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobDetails-aside-lists {
  height: 50px;
  width: 100%;
  background-color: #eff6ff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  box-shadow: 0px 4px 14px 0px #00000026;
}

.jobDetails-aside-data {
  height: 250px;
  border: 1px solid;
}

.jobDetails-aside-list-box {
  display: flex;
  /* border: 1px solid; */
  flex-direction: column;
  gap: 15px;
}

.jobDtails-section-heading-top-container {
  padding: 20px 40px;
  background-color: var(--color-lightgrey);
  box-shadow: 0px 4px 14px 0px #00000026;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jobDetails_NameHeading_main {
  font-family: Poppins;
  font-size: var(--font-size-26);
  font-weight: 600;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--color-lightblue);
}

.Jobdetails_Heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.jobdetailss-sectionsTwo-main-container {
  display: flex;

  /* gap: 15px; */
}

.jobdetails-form-customer-heading {
  color: var(--color-lightblue);
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-6);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin: 15px 0px;
}

@media screen and (max-width: 1150px) {
  .jobdetailss-sectionsTwo-main-container {
    display: none;
  }
}

.jobdetails_formContainer-box {
}

.jobdetails_formContainer-box-name {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.jobdetails_formContainer-box-name-sec1 {
  display: flex;
  gap: 20px;
}

.jobdetails_formContainer-box-name-sec2 {
  display: flex;
  gap: 20px;
}

.jobdetails_formContainer-box-label-fname {
  border: 1px solid;
  border-radius: 20px;
  padding: 10px 10px;
  border-color: #d3d3d3;
}

.jobdetails_formContainer-box-label-lname {
  border: 1px solid;
  border-radius: 20px;
  padding: 10px 10px;
  border-color: #d3d3d3;
}

.jobdetails_formContainer-box-label-contactno {
  border: 1px solid;
  border-radius: 20px;
  padding: 10px 10px;
  border-color: #d3d3d3;
}

.jobdetails_formContainer-box-label-email {
  border: 1px solid;
  border-radius: 20px;
  padding: 10px 10px;
  border-color: #d3d3d3;
}

.jobdetails_formContainer-box-name-sec1 input {
  border: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  width: 100px;
}

.jobdetails_formContainer-box-name-sec2 input {
  border: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  width: 250px;
}

.jobdetails_formContainer-box-name-sec1 label {
  color: #818181;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails_formContainer-box-name-sec2 label {
  color: #818181;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails_formContainer-box-installerAndHorizon-container {
  display: flex;
  flex: 1;
}

.jobdetails-horizontal-line-box {
}

.jobdetails_formContainer-box-properties-container {
  display: flex;
  padding: 10px 10px;
  flex: 4;

  flex-direction: column;
  gap: 25px;
}

.jobdetails_formContainer-box-installerAndProp-container {
  display: flex;
  margin-top: 20px;
}

.jobdetails_formContainer-box-installer-container {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  gap: 30px;
  width: 220px;
}

.jobdetails_formContainer-box-installer-date-box p {
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-6);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.jobdetails_formContainer-box-installer-date-box input {
  padding: 6px 10px;
  border-color: 2px solid #d3d3d3;
  border-radius: 20px;
}

.jobdetails_formContainer-box-installer-date-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jobdetails_formContainer-box-installerI-container {
}

.jobdetails_formContainer-box-installerI-container span {
  color: var(--color-lightblue);
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-6);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails_formContainer-box-installerI-container p {
  font-family: poppins;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-6);
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #212121;
}

.jobdetails_formContainer-box-chnageInstaller-container {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}

.jobdetails_formContainer-box-chnageInstaller-container span {
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails_formContainer-box-chnageInstaller-container img {
  width: 9px;
  height: 9px;
}

.jobdetails_formContainer-box-properties-container p {
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-6);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.jobdetails_formContainer-box-properties-propertyType {
  display: flex;
  gap: 20px;
}

.jobdetails-form-propertyType {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 6px 15px;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails-form-propertyType select {
  border: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails-form-NMI {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 5px 15px;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails-form-NMI input {
  border: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

.jobdetails-form-Grid {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 5px 15px;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
}

#propertyTypeId {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.jobdetails_searchAdress-input input[type="search"] {
  border: none;
}

.jobdetails_searchAdress-input input {
  /* border: none; */
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  /* width: 525px; */
  border: none;
}

.jobdetails_searchAdress-input img {
  cursor: pointer;
}

.jobdetails_searchAdress-input {
  border: 1px solid #d3d3d3;
  /* display: flex; */
  /* align-items: center; */
  border-radius: 20px;
  padding: 6px 25px;
  /* justify-content: space-between; */
  display: flex;
  align-items: center;
  flex: 1;
}

.InstallerDetails_popup_address-container {
  display: flex;
  align-items: center;
}

.jobdetails-form-address-lineOne {
  display: flex;
  gap: 35px;
  /* justify-content: space-between; */
}

.address-auto-complete {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.address-auto-complete div {
  padding: 10px;
  cursor: pointer;
}

.address-auto-complete div:hover {
  color: var(--color-lightblue);
}

.address-auto-complete .loading {
  padding: 10px;
}

.address-auto-complete .no-suggestions {
  padding: 10px;
}

#address:focus {
  border-color: var(--color-lightblue);
  /* Change the border color when focused */
}

.jobdetails-form-address-lineOne input {
  border: none;
  width: 50px;
}

.createjob-subhurb_inputVal {
  width: 100px;
}

.jobdetails-form-address-lineOne-box {
  border: 1px solid;
  border-radius: 20px;
  padding: 6px 10px;
  border-color: #d3d3d3;
  background-color: white;
  gap: 10px;
  align-items: center;
  display: flex;

  font-family: poppins;
  color: #818181;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.jobdetails-form-address-lineOne-box:focus-within {
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}

.jobdetails-form-address-lineOne-box select {
  border: none;
  font-size: var(--font-size-16);
  font-family: poppins;
}

/* .jobdetails-form-address-lineOne-box span{
  font-family: poppins;
font-size: var(--font-size-16);
font-weight: var(--font-weight-4);
line-height: 27px;
letter-spacing: 0em;
text-align: left;

  color: #818181;
} */
.jobdetails-form-address-lineTwo {
  display: flex;
  gap: 35px;
}

.jobdetails-form-address-lineOne-box input {
  font-size: var(--font-size-16);
  font-family: poppins;
}

.jobdetails-form-address-lineTwo-box {
  border: 1px solid;
  border-radius: 20px;
  padding: 6px 10px;
  border-color: #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.jobdetails-form-address-lineTwo-box input {
  border: none;
  width: 70px;
}

.jobdetails-form-address-lineTwo-box label {
  color: #818181;
}

.jobdetails-submit-btn-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.jobtails-submit-submitBtn {
  border: none;
  /* width: 194px;
  height: 47px; */
  border-radius: 30px;
  padding: var(---btn-regu-gap);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-lightblue);
  gap: 10px;
  cursor: pointer;
}

.jobdetails-submit-btn-container img {
  padding: 5px;
  background-color: white;
  height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 40px;
}

.jobdetails_formContainer-box-installerI-iTag {
  display: inline-block;
  width: 13px;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  background-color: var(--color-lightblue);
}

.jobdetails_formContainer-box-installerI-iTag span {
  display: inline-block;
  color: white;
  font-family: poppins;
  font-size: var(--font-size-10);
  font-weight: var(--font-weight-6);
  line-height: 8px;
  letter-spacing: 0em;
  text-align: center;
}

.jobdetails-submit-btn-container span {
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  color: white;
}

.jobtails-submit-saveExit {
  border: none;
  padding: var(---btn-regu-gap);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-lightblue);
  gap: 10px;
  cursor: pointer;
}

.jobdetails-form-address-selection-box {
  border-color: #d3d3d3;
  border-radius: 20px;
  padding: 6px 6px;
  color: #818181;
}

.popUpinstallerlist_installationD_container_box {
  width: 650px;
  height: 90vh;
}

.installerDetails_Address_Button {
  display: flex;
  align-items: center;
  gap: 10px;
}

/** JObDetails.jsx End here  **/

/** JobList.jsx start from here **/

.jobList-container {
  display: flex;
  justify-content: center;
  padding: 30px;
  box-shadow: 0px 4px 44px 0px #0000001a;
  border-radius: 40px;
}

.parent_section-one-joblist {
  /* background-color: #eff6ff; */
  border-radius: 40px;
  border: none;
  /* padding: 30px; */
  width: 1030px;
  margin-right: 15px;
  flex-grow: 1;
}

.parent_section-two-joblist {
  display: flex;
  /* flex-direction: column; */
  /* padding: 30px 10px; */
  /* gap: 15px; */
}

.joblist-datas-withdetails {
  border: 1px solid;
  background-color: #ffffff;
}

.Joblist-filter-pending {
  width: 190px;
  background-color: var(--color-lightgrey);
}

.Joblist-filter-pending {
  padding: 25px 15px;
  width: 250px;
  height: 360px;
  border-radius: 30px;
  border: 1px solid #acb4b4;
}

.Joblist-filter-pending a {
  text-decoration: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #606060;
}

.Joblist-filter-pending p {
  font-family: poppins;
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-6);
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.joblist-pending-container ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

.joblist-pending-container ul li a:hover {
  color: var(--color-lightblue);
  transition: color 0.3s;
}

.joblist-fliter-container ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
}

.joblist-fliter-container ul li a:hover {
  color: var(--color-lightblue);
  transition: color 0.3s;
}

.joblist-status-container {
  display: flex;
  gap: 20px;
}

.joblist-notification-box {
  text-align: center;
  border: 1px solid #acb4b4;
  width: 170px;
  height: 140px;
  border-radius: 24px;
  background-color: var(--color-lightgrey);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.JobDetailsAllphotosStatus img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.JobDetailsAllphotosStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 5px;
}

.jobDetails-aside-data_Aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* border: 1px solid; */
}

.JobdetailsJobstatus {
  flex-wrap: wrap;
  text-align: center;
  font-size: var(--font-size-10);
  width: 275px;
  gap: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid; */
  justify-content: center;
}

.jobDtails_Aside_Heading_status {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #5fa4fb;
}

/** JobList.jsx End here **/

/** StatusTwo Start from here **/

.statusTwo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusTwo-status-box {
  height: 150px;
  width: 760px;
}

.statusTwo-notification-icon {
  height: 150px;
  background-color: white;
  width: 190px;
  border-radius: 40px;
  cursor: pointer;
  border: none;
  background-color: white;
  text-align: center;
}

.statusTwo-manage-btn {
  width: 760px;
  text-align: end;
}

.statusTwo-manage-btn a {
  text-decoration: none;
  color: black;
}

/** StatusTwo End here **/

/** Installer.jsx Start from here **/
.installer-section-one {
  /* flex: 80%; */
  margin-right: 10px;
}

.installer-section-two {
  /* flex: 20%; */
  display: flex;
  gap: 10px;
}

.installer-section-one {
  /* background-color: #f5f5f5; */

  border-radius: 40px;
}

.installer-container {
  display: flex;
}

.search-installer-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.createJob-conatiner {
  /* position: relative; */
  cursor: pointer;
}

.plus-icon-search {
  position: absolute;
  top: -18px;
  right: 15px;
}

.installer-input {
  height: 50px;
  width: 900px;

  border-radius: 30px;
  border: none;
}

.searchicon-installer-input {
  position: absolute;
  right: 20px;
  top: 13px;
}

.searchbox-conatiner-installer input[type="text"] {
  padding-left: 30px;
  font-size: var(--font-size-18);
  width: 100%;
  border: 1px solid #d3d3d3;
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-4);
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #696969;
}

.installer-main-dataContainer {
  /* padding-left: 20px; */
}

.searchbox-conatiner-installer {
  position: relative;
  flex: 1;
}

.installer-profile-container-details {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.installer-profile-container-details P {
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-5);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.installer-profile-container-details span {
  font-family: poppins;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #00000099;
}

.installer-profile-container-details img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50px;
}

.installer-editjobicon-imgBox {
  border: 1px solid;
  background-color: white;
  align-items: center;
  display: flex;

  justify-content: center;
  border: none;
  /* border: 1px solid; */
  background-color: white;
  padding: 5px;
  border-radius: 37px;

  /* height: 40px;
  width: 40px; */
}

.installer-editjobicon-imgBox img {
  cursor: pointer;
  padding: 6px;
}

.installerHeading_container-name {
  width: 200px;
}

.personimg-details-json {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-lightgrey);
  border-radius: 40px;
  align-items: center;
  padding: 10px 10px;
  margin: 20px 0px;
}

.circle-img-installer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#circle-child-img-task1 {
  margin: -10px;
}

#circle-child-img-task2 {
  margin: -10px;
}

#circle-child-img-task3 {
  margin: -10px;
}

.circle-status-container-notConnected {
  text-align: center;
  height: 25px;
  width: 140px;
  border-radius: 8px;
  background-color: #ffeded;
}

.circle-status-container-Connected {
  text-align: center;
  height: 25px;
  width: 150px;
  border-radius: 8px;
  background-color: #ffeded;
}

.filter-profile-data-json ul {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;

  padding: 0 50px 0px 35px;
}

.profile-container-details-one span {
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-3);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.profile-input-box {
  /* margin: 10px 10px; */
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.profile-container-details-two span {
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-3);
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.profile-input-box input {
  /* width: 410px; */
  height: 36px;
  flex: 2;

  border-radius: 18px;
  border: 1px solid #dadada;
  font-size: var(--font-size-14);
  padding: 20px;
  border-color: #dadada;
}

.Installer-filter-container {
  border: 1px solid #acb4b4;
  width: 280px;
  height: 370px;
  border-radius: 30px;
  background-color: var(--color-lightgrey);
  padding: 30px;
}

.Installer-aside_container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  gap: 60px;
}

@media screen and (max-width: 1150px) {
  .Installer-aside_container {
    display: none;
  }
}

.installerProfile_PopUpI-icon {
  /* border: 1px solid; */
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.installerProfile_PopUpI-icon img {
  cursor: pointer;
  width: 25px;
}

.installer-filter-heading {
  font-family: poppins;
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-6);
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
  margin-bottom: 15px;
}

.installer-filter-boxes {
  /* border: 1px solid; */
  text-align: center;
  padding: 10px;
  margin-bottom: 15px;

  text-decoration: none;
  font-family: poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #606060;
}

.installer-filter-boxes:hover {
  cursor: pointer;
  color: var(--color-lightblue);
}

.Installer_actions-containers {
  /* border: 1px solid; */
  display: flex;
  gap: 10px;
}

.popUp-eleLic-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popUp-cicId-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Installer_actions-containers img {
  cursor: pointer;
}

/** Installer.jsx End Here **/

/** ---------   Profiledashboard.jsx  start from here  ------ **/

/* .ProfileDashboard_container{
  display: flex;
  justify-content: center;
  align-items: center;
  
} */

@media screen and (min-width: 1600px) {
  .ProfileDashboard_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button .btn-createjob {
    right: 250px !important;
  }
}

.profileDashboard-main-container {
  /* background-color:#EFF6FF; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* padding: 30px 60px 30px 40px; */
  padding: 30px;
  /* box-shadow: 0px 4px 44px 0px #0000001a;
  border-radius: 40px; */
  /* gap: 15px; */
}

.profileDashboard-container-profile-main {
  background-color: #f5f5f5;
  border-radius: 40px;
  /* padding: 30px 30px 50px 30px; */
  padding: 50px 60px;
}

.profiledashboardJsx {
  background-color: #eff6ff;
}

.profile-container-details {
  /* width: 1308px; */
  height: 700px;
  background-color: white;
  border-radius: 40px;
}

.profile-component {
  /* padding: 30px; */
}

.Profile-top-section {
  display: flex;
  gap: 10px;
}

.profile-container-details-two {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.profile-container-details-one {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.profileDashbord-status-component {
}

.profile-container-box {
  display: flex;
  gap: 20px;
  /* border: 1px solid; */
  justify-content: space-between;

  margin-top: 40px;
}

.profile-input-box label {
  font-size: var(--font-size-14);
  padding-right: 15px;
  flex: 1;
}

.profileDashbord-status-component {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.profileDashboard-notification-box {
  /* width: 421px; */
  flex: 1;
  border-radius: 24px;
  text-align: center;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-lightgrey);
  border: 1px solid #acb4b4;
}

.Profile-Status-container {
  flex: 2;
}

.profileDashboard_toheader-div {
  flex-grow: 2;
}

.profileDashboard_Profile-div {
  flex-grow: 1;
}

.profileDashboard-editbtn-box {
  display: flex;
  justify-content: space-between;
  padding: 0px 70px;
  margin-top: 20px;
}

.profileDashboard-editbtn-box button {
  cursor: pointer;
  border: none;
  background-color: var(--color-lightblue);
  border-radius: var(--btn-radius);
  padding: var(---btn-regu-gap);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-16);
  color: white;
  gap: 10px;
}

.profileDashboard-editbtn-box img {
  border: 1px solid;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 20px;
  border: 1px solid;
}

.ProfileDashboard_Profile_container_hidden {
}

@media (min-width:1020px) and (max-width: 1280px) {
  .ProfileDashboard_Profile_container_hidden {
    display: none;
  }
  /* .resetpassword-container{
    width: 80% !important;
    max-width: 60% !important;
    } */
  /* input.psd-toggle-reset{
      width: 520px !important;
    } */
}

/** ---------   Profiledashboard.jsx  End  here  ------ **/

/**  PopUpProfile.jsx Start from here **/

.PopUp-Profile-container {
  width: 760px;
  height: 400px;
  background-color: #eff6ff;
  border-radius: 40px;
  display: flex;
  justify-content: space-between;
  padding: 25px 20px;
  box-shadow: 0px 2px 30px 0px #00000026;
  bottom: 350px;
  top: 100px;
  left: 300px;
  /* position: fixed; */
}

.PopUp-profile-image-sec1 {
  /* border: 1px solid black; */
}

.popup-imgAnddetails-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.popUp-profile-basic-details-sec2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0px 20px 30px;
}

.popUp-Profile-main-img {
  border-radius: 999px;
  height: 120px;
  width: 120px;
}

.popUp-Profile-main-img img {
  width: 100%;
  height: 100%;
}

.popUp-basic-sec1 {
}

.popUp-basic-name p {
  font-size: var(--font-size-22);
  font-weight: var(--font-weight-3);
}

.popUp-basic-name span {
  color: #00000099;
}

.popUp-basic-sec2 {
  gap: 15px;
  display: flex;
  flex-direction: column;
}

.popUpProfile-basic-heading {
  display: flex;
  align-items: center;
  color: var(--color-lightblue);
  font-size: var(--font-size-22);
}

.popUp-basic-liNo {
  display: flex;
  /* gap: 20px; */
  padding-right: 20px;
  justify-content: space-between;
}

.popUp-basic-liNo span {
  color: red;
  font-size: var(--font-size-10);
}

.popUp-basic-sec3 {
}

.popUp-basic-sec3 button {
  background-color: #d7eea1;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  cursor: pointer;
}

.popUp-Profile-homedetails-box-sec3 {
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  background-color: #ffffff;
  border-radius: 40px;

  padding: 20px 20px;
  box-shadow: 0px 4px 14px 0px #00000026;
}

.popUp-homedetails-box1 {
  display: flex;
  gap: 10px;
}

.popUp-homedetails-box1 p {
  color: var(--color-lightblue);
  font-size: var(--font-size-16);
}

.popUp-homedetails-box1 span {
  color: #00000099;
}

.popUp-homedetails-box2 {
}

.popUp-homedetails-addr {
  color: var(--color-lightblue);
  font-size: var(--font-size-16);
}

.popUp-homedetails-addrBox {
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  display: grid;
}

.popUp-homedetails-addrBox p {
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-6);
  color: #000000;
}

.popUp-homedetails-addrBox span {
  color: #00000099;
}

.popUp-homedetails-box3 {
}

.popUp-homedetails-box3 p {
  font-size: var(--font-size-16);

  color: #000000;
}

.popUp-homedetails-sec1 img {
  width: 30px;
  height: 30px;

  border: 1px solid var(--color-lightblue);
  border-radius: 55px;
  display: flex;
  gap: 5px;
}

.popUp-homedetails-sec1 {
  display: flex;
  gap: 5px;
}

.popUp-homedetails-pen img {
}

.popUp-homedetails-pen {
  width: 30px;
  height: 30px;
  border: 1px solid #e3e3e3;
  border-radius: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.popUp-homedetails-img {
  display: flex;
  justify-content: space-between;
}

/**  PopUpProfile.jsx End here **/

/**  TodayBoxStraightToggle.jsx css Start from here  **/

/* .joblist-box {
  border: 1px solid;
  position: relative;
  border-radius: 40px;
  background-color: #fff;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.25rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 1rem;
  color: var(--color-lightblue);
  font-family: poppins;
  width: 720px;
} */

.joblist-container-box {
  background-color: var(--color-lightgrey);
  display: flex;

  border-radius: 40px;
}

.customernameheading-box {
  align-self: stretch;
  position: relative;
  line-height: 1.13rem;
  font-weight: var(--font-weight-6);
  font-family: Work Sans;
  color: var(--color-lightblue);
}

.customername-box {
  margin: 0;
  position: relative;
  font-size: var(--font-size-20);
  line-height: 1.88rem;
  font-weight: var(--font-weight-7);
  font-family: inherit;
  color: #2d2d2d;
  display: flex;
  align-items: center;
  width: 158px;
}

.customer-heading-customerName {
  color: var(--color-lightblue);
  font-weight: var(--font-weight-6);
  font-family: Work Sans;
}

.river-lane-port-box {
  margin: 0;
}

.customeraddress-box {
  position: relative;
  font-size: 1.13rem;
  line-height: 1.38rem;
  color: #525252;
}

.customerdata-box {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 28px; */
}

.i-box,
.installerheading-box {
  /* position: relative; */
  font-weight: var(--font-weight-6);
}

.installerheading-box {
  line-height: 1.13rem;
  font-family: Work Sans;
  color: var(--color-lightblue);
}

.i {
  line-height: 0.5rem;
}

.popup-4 {
  display: flex;
  gap: 5px;
}

.installerinfobtn-box {
  cursor: pointer;
  width: 20px;
  border-radius: 20px;
  background-color: var(--color-lightblue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.13rem 0.31rem;
  font-size: 0.5rem;
  color: #fff;
}

.installername-box {
  position: relative;
  line-height: 1.13rem;
  font-weight: var(--font-weight-6);
  color: #212121;
}

.installerdata-box {
  width: 135px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.customer-certificate-box {
  position: relative;
  font-weight: var(--font-weight-6);
}

.customercertidownloadbtn-child-box {
  position: relative;
  width: 8.7px;
  height: 10.2px;
}

.customercertidownloadbtn-box {
  cursor: pointer;
  align-self: stretch;
  border-radius: 14px;
  background-color: #d7eea1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px;
  font-size: 0.88rem;
  color: #525252;
  gap: 9px;
}

.startside-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 245px;

  justify-content: space-between;
}

.installdate-box {
  position: relative;
  letter-spacing: 0.03em;
  line-height: 1.38rem;
  font-weight: var(--font-weight-6);
}

.completed-box {
  position: relative;
  font-weight: var(--font-weight-5);
}

.datedata-box,
.jobcompletestatus-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.jobcompletestatus-box {
  border-radius: 30px;
  background-color: #d7eea1;
  justify-content: center;
  padding: 0.13rem 0.25rem;
  font-size: 0.63rem;
  color: #525252;
}

.datedata-box {
  width: 120px;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 4px;
}

.downloadbtn-child-box {
  position: relative;
  width: 24px;
  height: 24px;
}

.downloadbtn-box,
.middletopside-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.downloadbtn-box {
  cursor: pointer;
  border-radius: 30px;
  background-color: var(--color-lightblue);
  width: 140px;
  align-items: center;
  padding: 0.25rem 0.95rem;
  box-sizing: border-box;
  font-size: 0.88rem;
  color: #fff;
}

.middletopside-box {
  align-self: stretch;
  align-items: flex-start;
  font-size: 1.13rem;
}

.allphotosstatus-child-box {
  position: relative;
  width: 14.5px;
  height: 23.4px;
}

.div-box {
  position: relative;
  letter-spacing: -0.06em;
  line-height: 0.63rem;
  font-weight: var(--font-weight-6);
}

.allphotosstatus-box,
.noofphotos-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.allphotosstatus-box {
  border-radius: 5px;
  background-color: #eff6ff;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
}

.panelscanstatus-child-box,
.vector-icon-box {
  position: relative;
  width: 30px;
  height: 30px;
}

.vector-icon-box {
  width: 23px;
}

.designerstatus-child-box {
  position: relative;
  width: 22px;
  height: 30.4px;
}

.inverterscanstatus-child-box,
.layer-21-icon-box {
  position: relative;
  width: 25px;
  height: 30px;
}

.layer-21-icon-box {
  width: 26.8px;
  height: 28px;
}

.customersignstatus-child-box {
  position: relative;
  width: 29.5px;
  height: 27px;
}

.retailerdeclarationstatus-child-box {
  position: relative;
  width: 31px;
  height: 29px;
}

.startselfiestatus-child-box {
  position: relative;
  width: 30px;
  height: 31.2px;
}

.startselfiestatus-box {
  border-radius: 5px;
  background-color: #eff6ff;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.endselfiestatus-child-box {
  position: relative;
  width: 29px;
  height: 30.2px;
}

.spvstatus-box {
  border-radius: 5px;
  background-color: #eff6ff;
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: 0.94rem;
  color: #464646;
}

.jobstatus-box {
  align-self: stretch;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  text-align: center;
}

.jobstatus-box img {
  width: 100%;
  height: 100%;
}

.ccdownloadbtn-box,
.jobstatus-box,
.middleside-box {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.ccdownloadbtn-box {
  cursor: pointer;
  border-radius: 14px;
  background-color: #d7eea1;
  flex-direction: row;
  padding: 0.25rem 1.25rem;
  gap: 10px;
  color: #525252;
  margin-top: 12px;
}

.middleside-box {
  width: 300px;

  flex-direction: column;
  gap: 41px;
  font-size: 0.75rem;
  color: #000;
}

.jobdetailsbtn-box,
.stcheading-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.jobdetailsbtn-box {
  cursor: pointer;
  align-self: stretch;
  border-radius: 30px;
  background-color: var(--color-lightblue);
  justify-content: space-between;
  padding: 0.25rem 0.95rem;
  color: #fff;
  text-decoration: none;
}

.stcheading-box {
  justify-content: center;
  font-size: 1.13rem;
  color: #000;
  font-family: Work Sans;
}

.stcamoutheading-box {
  line-height: 1.5rem;
  font-weight: var(--font-weight-6);
}

.stcamount {
  display: flex;
  gap: 5px;
}

.stc-amout-box,
.stcamoutheading-box {
  position: relative;
}

.stcamount-box {
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
}

.stcamount-box,
.stcdetails-box,
.systemsize-box {
  display: flex;
  align-items: flex-start;
}

.systemsize-box {
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.stcdetails-box {
  width: 147px;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
}

.unpaid-box {
  position: relative;
  font-size: 0.88rem;
  font-weight: var(--font-weight-6);
  font-family: Poppins;
  color: #fff;
  text-align: left;
}

.stcformdownloadbtn-box,
.stcpaymentstatus-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.stcpaymentstatus-box {
  border: 0;
  padding: 0.06rem 0.95rem;
  background-color: #ff7d00;
  border-radius: 30px;
}

.stcformdownloadbtn-box {
  cursor: pointer;
  align-self: stretch;
  border-radius: 14px;
  background-color: #d7eea1;
  padding: 0.25rem 0.5rem;
  gap: 10px;
  font-size: 0.75rem;
}

.endside-box,
.joblist-box {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
}

.endside-box {
  flex-direction: column;
  justify-content: center;

  gap: 21px;
  font-size: 0.88rem;
  color: #525252;
}

.joblist-box {
  border-radius: 40px;
  height: 290px;
  padding: 10px 0px;
  padding: 21px 21px;
  border: 1px solid #acb4b4;
  background-color: var(--color-lightgrey);
  /* display: flex;
  position: relative;

  background-color: #fff;
  width: 750px;
  /* flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1.25rem;
  box-sizing: border-box;
  text-align: left;
  font-size: 1rem;
  color: var(--color-lightblue);
  font-family: poppins;
  align-items: center; */
}

.todayJob-stratight-toggle-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.Joblist-filter-pending {
}

.todayJob-stratight-toggle-container {
  display: flex;
}

.TodayStrtToggl-mapData-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-wrap: wrap;
}

/** TodayBoxStraightToggle.jsx css End here **/

/* PopupSelectInstaller start */
.PopupSelectInstaller {
  width: 600px;
  /* width: 550px;
display: flex;
justify-content: center;
align-items: center; */
  position: absolute;
  bottom: 0px;
  left: 260px;
}

.popupSelectIns-circle-status-container-Connected {
  text-align: center;
  width: 90px;
  background-color: #d7eea1;
  border-radius: 8px;
  cursor: pointer;
}

.popupSelectIns-circle-status-container-notConnected {
  text-align: center;
  width: 90px;
  background-color: #d7eea1;
  border-radius: 8px;
  cursor: pointer;
}

/* PopupSelectInstaller end */

/* Createjob.jsx start here */
.CreateJob_container {
  padding: 20px 50px;
}

.createjob_main-container {
  background-color: var(--color-lightgrey);
  box-shadow: 0px 4px 20px 9px #00000017;
  border-radius: 20px 40px;

  /* padding: 50px 100px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: scroll;
  padding: 40px 30px;
}

.createjob_main-container section {
  border: 1px solid #d3d3d3;
  padding: 30px;
  border-radius: 20px;
}

.createjobPopup-step2_main-container {
}

.createjob_main-heading-p {
  font-family: Poppins;
  font-size: 32px;
  font-weight: var(--font-weight-5);
  line-height: 63px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.createjob_main-heading-span {
  font-family: Poppins;
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-4);
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #303030;
}

.createjob_customer-name-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createjob-p-container_heading {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
}

/* .create-job-input_labels{
  color: #818181;
  font-family: poppins;
  font-weight: Regular;
} */

.createjob_listview_div_container {
  display: flex;
  gap: 10px;
}

.createjob-fname-div-box {
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #818181;
}

.createjob-fname-div-box input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  color: #000000;
  width: 100px;
}

.createjob-lname-div-box {
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #818181;
}

.createjob-lname-div-box input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  color: #000000;
  width: 100px;
}

.createjob-contactNo-div-box {
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;

  color: #818181;
}

.createjob-contactNo-div-box input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  color: #000000;
}

.createjob-email-div-box {
  border: 1px solid hsl(0, 0%, 83%);
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #818181;
}

.createjob-email-div-box input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  color: #000000;
}

.createjob-grid-box {
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  gap: 10px;
  color: #818181;
  align-items: center;
  font-size: var(--font-size-16);
  cursor: pointer;
}

.createjob-nmi-box {
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  gap: 10px;
  color: #818181;
  align-items: center;
}

.createjob-nmi-box input {
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  color: #000000;
}

.createjob-propertyType-residential_box {
  border: 1px solid #d3d3d3;
  padding: 5px 30px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  gap: 10px;

  align-items: center;
}

.createjob-propertyType-residential_box input[type="radio"]:checked + label {
  color: var(--color-lightblue);
}

.create_job-propertyType-box {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.createjob-selected-text-propertyType {
  cursor: pointer;
}

.createjob-propertyType_container-main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createjob-propertyAddress_main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createjob_addressSearch_input-box {
  border: 1px solid #d3d3d3;
  display: flex;
  background-color: white;
  padding: 5px 15px;
  border-radius: 20px;

  align-items: center;
}

.createjob_addressSearch_input-box input {
  border: none;
  flex: 1;
  font-size: var(--font-size-16);
  font-family: Poppins;
}

.createjob_addressSearch_input-box img {
  background-color: white;
  cursor: pointer;
}

.createjob-installationDetails_main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.createjob_installation-date-box {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  flex-wrap: wrap;
}

.createjob_installation-listview_div_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.createjob-date-input input {
  padding: 5px 15px;
  border-radius: 20px;
  border: none;
  border: 1px solid #d3d3d3;
}

.createjob-date-input input {
  font-family: Poppins;
  font-size: var(--font-size-16);
  cursor: pointer;
}

.create-job-selectInstaller-box {
  font-family: Poppins;
  font-size: var(--font-size-16);
  display: flex;
  align-items: center;
  border: 1px solid #d3d3d3;
  padding: 5px 15px;
  border-radius: 20px;
  gap: 10px;
  background-color: white;
  cursor: pointer;
}

.create-job-selectInstaller-box:hover {
  border: 1px solid #5fa4fb;
}

.createjob-installerdetails-button-box {
  padding: var(---btn-regu-gap);
  background-color: var(--color-lightblue);
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: var(--btn-radius);
}

.createjob-installerdetails-button-box img {
  background-color: white;
  border-radius: 40px;
  padding: 4px;
}

.createjob-installerdetails-button-box button {
  border: none;
  color: white;
  background-color: var(--color-lightblue);
  cursor: pointer;

  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
}

.form-transition-enter {
  opacity: 0;
  transform: translateX(100%);
}

.form-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.form-transition-exit {
  opacity: 1;
  transform: translateX(0);
}

.form-transition-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.createjob-heading-systemDetails {
  color: #000000;
  font-family: Poppins;
  font-size: 42px;
}

.createjob-systemDetails-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* background-color: var(--color-lightgrey);
  padding: 15px 30px 10px 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;  */
  /* border: 1px solid; */
  /* justify-content: space-between;
  box-shadow: 0px 4px 20px 9px #00000017; */
}

.createjob-systemDetails_heading {
}

.createjob-heading-svpjob {
  font-family: Poppins;
  font-size: var(--font-size-20);
  color: #000000;
}

.createjob_panelsDetails-box {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.createJob_panelDetails-main-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createJob_panelDetails-main-container p {
  font-size: var(--font-size-20);
  font-family: Poppins;
  color: #303030;
}

.createjob-selection-streetType option {
  width: 250px;
  height: 350px;
}

.createjob-selection-streetType {
  border: none;
}

.createjob-installtionType-selection-box {
  border: none;
  font-size: var(--font-size-16);
  font-family: Poppins;
  cursor: pointer;
}

.createjob-menualyAddress-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createjob-box-all-options {
  border: 1px solid #d3d3d3;
  background-color: white;
  padding: 5px 15px;
  border-radius: 20px;
  gap: 10px;
  align-items: center;
  display: flex;
}

.createJOb-panelBoxes {
  display: flex;
  gap: 10px;
}

.createjob-box-all-options select {
  width: 90px;
}

.createjob-box-all-options input {
  width: 25px;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.createjob-box-all-options select {
  border: none;
  font-size: var(--font-size-16);
}

.createjob-box-all-options input {
  border: none;
}

#panelbrand-input {
  width: 150px;
  margin-left: 0;
  margin-right: 0;
}

.createjob_panelbrand_ul-container {
  background-color: white;
  border: 1px solid #d3d3d3;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  border-radius: 7px;
}

.Createjob_property-sectionOne_types {
  /* border: 1px solid; */
  display: flex;
  gap: 20px;
}

.createjob_additionalDetails-main_container {
  /* border: 1px solid; */

  gap: 25px;
  display: flex;
  flex-direction: column;
}

.Createjob-secitonOne-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Createjob-secitonOne-container div {
  /* border: 1px solid; */
}

.createjob-additionalDetails_button_container {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.createjob-div_hoverAll-box:focus-within {
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}

.Signup-autoAdress_btn-container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.Createjob_Address_MenualBtn {
  background-color: var(--color-lightblue);
  color: white;
  padding: var(---btn-regu-gap);
  border: none;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-6);
  /* line-height: 35px; */
  letter-spacing: 0em;
  text-align: left;
  border-radius: var(--btn-radius);
  cursor: pointer;
}

.Create_Job-form-address-lineOne {
  display: flex;
  gap: 20px;
}

#Sigup-manual-uniNo {
  border: none;
  width: 35px;
}

#Sigup-manual-StreetNo {
  border: none;
  width: 35px;
}

#Sigup-manual-StreetName {
  border: none;
  width: 100px;
}

#Sigup-manual-Streettype {
  border: none;
  width: 100px;
}

#Sigup-manual-Subhurb {
  border: none;
  width: 100px;
}

#Sigup-manual-Postcode {
  border: none;
  width: 35px;
}

.CreateJOb_Popupclose_container {
  /* border: 1px solid; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 30px;
}

.System_details_PopupCloseImg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.createjob_installer-Team {
  /* border: 1px solid; */
  display: flex;
  gap: 10px;
}

.createJob-installationTeam-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createjob_additioanldetails-referenceNo-container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.Createjob_uploadDocument-box {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.createjob-additionaDetails_refreno_boxes input {
  border: 1px solid #d3d3d3;
  /* padding: 10px; */
  border-radius: 20px;
  background-color: white;
}

.Createjob_uploadDocument-container {
  /* border: 1px solid; */
  display: flex;
  align-items: center;
  gap: 10px;
}

.createjob_secitonOne-intallationDetails_container {
  /* border: 1px solid;  */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.createjob-adition-extension-selection-container {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.createJob-installationType-aditionDetails-container {
  /* border: 1px solid; */
  display: flex;
  gap: 15px;
  flex-direction: column;
}

.createjob-Bothside-BoxesView {
  /* border: 1px solid; */
  display: flex;
  gap: 50px;
  /* flex-wrap: wrap; */
  /* align-items: center; */
}

.createjob-bothside-insideboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.createJOb-conbined-boxes {
  border: 1px solid #d3d3d3;
  /* border: 1px solid; */
  padding: 10px 20px;
  border-radius: 20px;
}

/* Createjob.jsx end here */

/* Compilances start here */

.compilance_popup_container {
  display: flex;
  /* border: 1px solid; */
  flex-wrap: wrap;
  gap: 40px;
}

.createpopup-backNextSev-button-container {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  margin: 15px 0px;
}

.button-container {
  /* display: flex; */
  /* justify-content: space-between; */
  width: 100%;
  /* Adjust the width as necessary */
  /* padding-top: 20px; */
  /* Add some space between the calendars and the buttons */
}

.createjob_additionalDetails_referenceNo {
  font-family: Poppins;

  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  text-align: left;
  display: flex;
  align-items: center;
  gap: 10px;
}

.createjob_additionalDetails_referenceNo input {
  border: 1px solid #d3d3d3;
  width: 300px;
  border-radius: 20px;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 15px;
  color: #000000;
}

.createjob_optimaisesInstaller-yes-no-container label {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 5px 15px;
  background-color: white;
  border-radius: 20px;
  color: #000000;
  cursor: pointer;
}

.createjob_roof-box {
  display: flex;
  gap: 10px;
}

.createjob_boxes-borderAll {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #818181;
}

.create-job-selectInstaller-box p {
  color: #818181;
}

.create-job-selectInstaller-box p:hover {
  color: #5fa4fb;
}

.createjob_boxes-borderAll:hover {
  border: 1px solid #5fa4fb;
  color: #5fa4fb;
}

.createjob_optimaisesInstaller-yes-no-container label:hover {
  color: #5fa4fb;
  border: 1px solid #5fa4fb;
}

.createjob_boxes-borderAll input {
  border: none;
}

.createjob_optimisesInstalled-container {
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 20px;
}

.createjob_roof-container {
  padding: 20px;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
}

.createjob_story-box {
  display: flex;
  gap: 15px;
}

.createjob_story-container {
  padding: 20px;
  font-family: Poppins;
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 20px;
  flex-direction: column;
  /* align-items: center; */
}

.createjob_aditonalDetails_roofandstory-container {
  display: flex;
  gap: 100px;
  align-items: center;
}

.createjob_panelType_box {
  display: flex;
  gap: 10px;
}

.createjob_phase-box {
  display: flex;
  gap: 10px;
}

.createjob_panelType_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
}

.createjob_panelAndPhase-container {
  display: flex;
  align-items: center;
  gap: 100px;
}

.createjob_Phase_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* align-items: center; */
}

.compilances_container-All_boxes {
  /* width: 360px;
  height: 220px; */
  background-color: var(--color-lightgrey);
  text-align: center;
  border-radius: 20px;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 30px;
  box-shadow: 0px 4px 14px 0px #00000026;
}

.editjob_details-containers {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.compilances_container-All_boxes p {
  font-family: poppins;
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-5);
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  text-align: center;
}

/* .createjob_optimaisesInstaller-yes-no-container input[type="radio"]:checked + label {
  color: var(--color-lightblue);
} */

.createjob_optimaisesInstaller-yes-no-container
  input[type="radio"]:checked
  + label {
  color: var(--color-lightblue);
  border: 1px solid var(--color-lightblue);
}

/* Compilances end  here */

.addbtryBTN {
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  padding: 5px 15px;
  width: fit-content;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  transition: ease-in;
  font-family: poppins;

  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.addbtryBTN:hover {
  border: 1px solid var(--color-lightblue);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.createjob-box-all-options:hover {
  border: 1px solid var(--color-lightblue);

  font-weight: var(--font-weight-4);
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
  cursor: pointer;
}

.createjob-box-all-options select:hover {
  font-weight: var(--font-weight-4);
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
  cursor: pointer;
}

.createjob-div_hoverAll-box:hover {
  border: 1px solid var(--color-lightblue);
  font-weight: var(--font-weight-4);
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.createjob-div_hoverAll-box label:hover {
  font-weight: var(--font-weight-4);
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.createjob-propertyType-residential_box:hover {
  border: 1px solid var(--color-lightblue);
  font-weight: var(--font-weight-4);
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.jobdetails-form-address-lineOne-box:hover {
  border: 1px solid var(--color-lightblue);
  font-weight: var(--font-weight-4);
  /* line-height: 27px; */
  letter-spacing: 0em;
  text-align: left;
  color: var(--color-lightblue);
}

.jobdetails-form-address-lineOne-box span:hover {
  color: var(--color-lightblue);
}

.createjob_addressSearch_input-box:hover {
  border: 1px solid var(--color-lightblue);
  color: var(--color-lightblue);
}

.address-auto-complete-CreateJob {
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  cursor: pointer;
  border: 1px solid #d3d3d3;
  background-color: white;
  border-radius: 5px;
}

.address-auto-complete-CreateJob-value {
  /* margin: 0px 10px;   */
  background-color: white;
  border-radius: 5px;
}

.address-auto-complete-CreateJob-value:hover {
  color: var(--color-lightblue);
}

.data-display {
  height: 600px;
  overflow-y: auto;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
  font-size: 41px;
  font-weight: 300;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.react-calendar__navigation__label {
  font-size: 18px;
  font-weight: 500;
}

/*new login page css*/
.login-container {
  display: flex;
  width: 100%;
  background-image: url("../public/Assets/LogIn/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 900px; */
  overflow: hidden;
  position: relative;
  flex-direction: column;
  justify-content: center;
  z-index: 0;
  top: 0;
}

.bg-overlay {
  background: rgba(0, 67, 229, 0.3);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.form-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.logo {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 12px;
  margin: 0 auto;
  text-align: center;
}

section.login-box {
  border-radius: 0 !important;
  background-color: transparent;
}

section.login-box.main-box {
  background: rgba(255, 255, 255, 0.9);
  width: 457px;
  margin: 0 auto;
  border-bottom: 10px solid rgb(21 67 176);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 469px;
  padding-top: 0;
  gap: 6px;
}

.retailer-logo p {
  font-size: 18px;
  line-height: 58px;
  font-weight: 700;
  font-family: "Overpass";
  color: rgb(21 67 176);
}

.form-container input[type="text"] {
  width: 374px;
  height: 52px;
  border-radius: 5px;
  border: transparent;
  display: block;
  padding-left: 50px;
  background: rgba(224, 237, 255, 1);
  margin: 0;
  color: rgba(44, 58, 100, 1);
}

.psd-toggle {
  width: 374px;
  height: 52px;
  border-radius: 21px;
  border: 0.5px solid;
  border-radius: 50px;
  border: 1px solid rgba(44, 58, 100, 0.2);
  display: block;
  padding-left: 50px;
}

.login-form-imag.lock-password {
  background-image: url("../public/Assets/images/lock-main.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 10px;
  left: 15px;
  bottom: 5px;
}

.login-btn {
  width: 145px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  line-height: 15.31px;
  margin-top: 30px;
}

.login-form-imag {
  background-image: url("../public/Assets/images/email.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 21px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 17px;
  bottom: 5px;
  background-color: rgba(230, 229, 229, 0.2);
}

.eye-btn {
  position: absolute;
  cursor: pointer;
  right: 20px;
  top: 15px;
  border: none;
  background-color: rgba(230, 229, 229, 0.2);
}

.pas-form {
  position: relative;
  width: auto;
  height: auto;
  margin-top: 26px;
  margin-bottom: 10px;
}

.user-form {
  position: relative;
  width: auto;
  height: auto;
}

/* 
.pas-form input,
.user-form input {
  padding: 28px 15px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
}

.pas-form label,
.user-form label {
  position: absolute;
  top: 22px;
  left: 15px;
  pointer-events: none;
  color: rgba(44, 58, 100, 0.7);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
}

.pas-form input:not(:placeholder-shown) + label,
.user-form input:not(:placeholder-shown) + label,
.pas-form input:focus + label,
.user-form input:focus + label {
  top: 12px;
  left: 15px;
  font-size: 12px;
  color: #2c3a64;
} */

.checkbox {
  font-family: Inter;
  font-weight: 400;
  font-size: 15px;
  line-height: 14px;
  color: rgba(44, 58, 100, 1);
  display: flex;
  gap: 7px;
  cursor: pointer;
}

.form-container a {
  margin-top: 6px;
  text-align: end;
  font-size: 15px;
  line-height: 14px;
  font-weight: 400;
  color: rgba(44, 58, 100, 1);
  text-decoration: none;
}

.signup-text {
  margin: 0 auto;
  display: inline-block;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
  margin-right: 10px;
  text-decoration: none;
  text-align: center;
}

.Loging-signForg-container {
  display: block;
  padding: 0 10px;
  text-align: center;
  margin-top: 7px;
}

.Login_Fgpwd_container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

a.sign-up {
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
}

img.eye-login {
  width: 23px;
  height: 23px;
  display: block;
}

/*new forget passowrd page css*/

.ForgetPassword-main-container {
  display: flex;
  width: 100%;
  background-image: url("../public/Assets/LogIn/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 820px; */
  overflow: hidden;
  position: relative;
  z-index: 0;
  flex-direction: column;
  justify-content: center;
}

.ForgetPassword_main-conatainer-sec1.main-box {
  background: rgba(255, 255, 255, 0.9);
  width: 434px;
  margin: 0 auto;
  gap: 10px;
  border-bottom: 10px solid rgb(21 67 176);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  margin-top: 60px;
  padding-top: 0;
  border-radius: 0 !important;
}

.ForgetPassword-heading-container p {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 17.23px;
  font-weight: 700;
  color: rgb(21 67 176);
  margin-top: 30px;
}

.ForgetPassword-Email-box {
  position: relative;
  width: 374px;
  height: 52px;
  border-radius: 5px;
  border: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgba(224, 237, 255, 1);
}

.ForgetPassword-Email-box input {
  border: none;
  padding: 10px;
  border-radius: 5px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
}

.email-text {
  text-align: center;
  color: rgba(44, 58, 100, 1);
  margin-top: 10px;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
}

button.next.action-button {
  width: 157px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  display: inline-block;
  font-weight: 400;
  line-height: 15.31px;
  margin-top: 10px;
}

.login-text {
  margin: 0 auto;
  display: block;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 15px;
  margin-top: 10px;
  /* margin-right: 10px; */
  text-decoration: none;
  text-align: center;
}

.login-link {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  text-align: center;
  display: block;
}

.login {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  font-weight: 700;
  font-size: 15px;
  line-height: 14px;
  margin-left: 10px;
}

/*reset password page design css*/
.ForgetPassword-systemDetails-container {
  display: flex;
  align-items: center;
  width: 100%;
  background-image: url("../public/Assets/LogIn/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  position: relative;
  z-index: 0;
  border-radius: 0;
}

.resetpassword-container {
  background: rgba(255, 255, 255, 0.9);
  width: auto !important;
  /* margin: 0 auto; */
  gap: 0;
  display: flex;
  border-bottom: 10px solid rgb(21 67 176);
  justify-content: center;
  border-radius: 0 !important;
  align-items: center;
  /* position: relative; */
  flex-direction: column !important;
  justify-content: center;
  padding: 10px;
  /* max-width: 27% !important; */
}

.reset-text {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
}

.pas-form-reset {
  position: relative;
  width: fit-content;
  height: auto;
  margin-top: 17.47px;
}

input.psd-toggle {
  line-height: 14.36px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: transparent;
}

input.psd-toggle-reset {
  background: rgba(224, 237, 255, 1);
  width: 100%;
  height: 52px;
  border-radius: 5px;
  border: 0.5px solid;
  border-radius: 5px;
  border: none;
  display: block;
  padding-left: 50px;
}

.main-reset-box {
  display: inline-block;
}

.main-reset-box {
  position: absolute;
  top: 250px;
  left: 534px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reset-password-text {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 4oo;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

/*Sign up Page design css*/
.Signup-main-container {
  display: flex;
  width: 100%;
  background-image: url("../public/Assets/LogIn/solar-panel-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh ;
  overflow: scroll;
  position: relative;
  z-index: 0;
  border-radius: 0;
  align-items: center;
}

.Signup-heading-box {
  width: auto;
  /* margin: 0 auto; */
  gap: 0;
  /* border-bottom: 2px solid rgb(21 67 176); */
  display: flex;
  justify-content: center;
  /* margin-top: 60px; */
  padding-top: 0;
  border-radius: 0 !important;
  position: relative;
  text-decoration: none;
  display: flex;
  flex-direction: row;
}

.retailer-info {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  margin-top: 30px;
  padding-left: 20%;
}

.editmain-retailer {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  margin-top: 30px;
}
.main-profile-retailer {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  margin-top: 30px;
  padding-left: 14%;
  width: 82%;
  text-align: center;
}

.Signup-form-container {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  padding: 0px 0px 5px;
  /* position: absolute;
  top: 189px;
  right: 86px;
  left: 105px; */
  /* margin-top: 3%; */
  width: 100%;
  height: auto;
  width: fit-content;
  background: rgba(255, 255, 255, 0.9);
}

.Signup-basicDetails-boxes input {
  /* flex: 6 1; */
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  width: 284px;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 14px;
}

/* .Signup-form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
  position: absolute;
  top: 204px;
  right: 86px;
  left: 105px;
} */
/* 
.Signup-basicDetails-boxes {
  position: relative;
} */

/* .Signup-basicDetails-boxes input {
  padding: 28px 15px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
} */
/* 
.Signup-basicDetails-boxes input:focus {
  background-color: rgba(224, 237, 255, 0.8);
}

.Signup-basicDetails-boxes input::placeholder {
  color: transparent;
} */

/* .Signup-basicDetails-boxes input:not(:placeholder-shown) + label,
.Signup-basicDetails-boxes input:focus + label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: #2c3a64;
} */

/* .Signup-basicDetails-boxes label {
  position: absolute;
  top: 22px;
  left: 15px;
  pointer-events: none;
  color: rgba(44, 58, 100, 0.7);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
} */

.select-dropdown {
  width: 284px;
  height: 52px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: rgba(224, 237, 255, 1);
}

select.drop-main {
  width: 100%;
  border: 0;
  background: transparent;
  outline: 0;
  cursor: pointer;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 300;
  padding: 5px 0;
}

.Signup-basicDetails-boxes {
  /* border: 1px solid; */
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.Signup-basicDetails-boxes input[type="text"]:focus {
  border: none;
}

.Signup-conatiner-twoBox {
  /* border: 1px solid; */
  display: flex;
  gap: 0;
  flex-direction: column;
  padding: 15px;
}

.Signup-basicDetails-main-container p {
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  text-align: left;
}

.Signup-basicDetails-main-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1;
  margin-bottom: 10px;
  position: relative;
}

.Signup-basicDetails-boxes.input-box-main {
  width: max-content;
}

.input-information {
  position: relative;
  z-index: 0;
}

.info-button {
  position: absolute;
  top: 17px;
  display: flex;
  left: 88%;
  cursor: pointer;
}

.Signup-companyDetails-main-container {
  display: flex;
  flex-direction: column;
}

.company-details {
  display: flex;
  gap: 20px;
}

.input-address {
  width: 588px;
  height: 52px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: rgba(224, 237, 255, 1);
  outline: none;
}

input.address {
  border: none;
  background: transparent;
}

.Signup-switchingAdress-btn {
  color: rgb(21 67 176);
  font-size: 15px;
  font-weight: 700;
  border-radius: 0;
  background: transparent;
  text-decoration: underline;
  margin-top: 10px;
}

.Signup-Adress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin:0 20px 20px 0; */
}

.Signup-autoAdress-input {
  border: none;
  background: transparent;
  margin: 0;
  padding: 5px 0;
}

.bottom-line {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  opacity: 10%;
  margin-top: 20px;
  position: relative;
  top: 0;
}

.Signup-Adress-boxes input {
  /* width: 100px; */
  padding: 10px;
  border-radius: 5px;
  border: none;
  text-align: center;
  background: rgba(224, 237, 255, 1);
  text-align: left;
  width: 132px;
  height: 52px;
  font-size: 14px;
}

.Signup-address-boxes-container {
  margin: 0;
  padding: 0;
}

.Signup-address-boxes-container input[type="number"]:focus {
  border: 0;
}

.Signup-address-boxes-container input[type="text"]:focus {
  border: 0;
}

.street-boxes {
  padding: 10px;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  text-align: left;
  width: 284px;
  height: 52px;
  font-size: 14px;
  /* margin-right: 20px; */
}

.Signup-Address-String-boxes {
  background-color: transparent;
  outline: 0;
  border: none;
  padding: 5px;
}

.street-main-boxes {
  padding: 10px;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 284px;
  height: 52px;
  font-size: 14px;
  /* margin-right: 20px; */
}

.street-area {
  background-color: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding: 5px 0;
}

.suburb-boxes {
  padding: 10px;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 284px;
  height: 52px;
  font-size: 14px;
}

.suburb-area {
  background-color: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 300;
  padding: 5px 0;
}

.state-boxes {
  padding: 10px;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 284px;
  height: 52px;
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}

.state-main-area {
  background-color: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  padding: 5px 0;
}

.postcode-main {
  padding: 10px;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 284px;
  height: 52px;
  font-size: 14px;
}

.postcode-area {
  background-color: transparent;
  outline: 0;
  border: none;
  cursor: pointer;
  padding: 5px 0;
}

.checkbox {
  border-color: 1px solid rgb(21 67 176);
  accent-color: rgb(21 67 176);
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.btn-register {
  width: 157px;
  height: 41px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  /* margin: 0 auto; */
  display: inline-block;
  /* line-height: 15px; */
  /* margin-bottom: 20px; */
}

.Signup-Button-main-container button {
  background-color: transparent;
}

.Signup-Button-main-container button {
  background-color: transparent;
  color: white;
  border: none;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
}

.Signup-Button-main-container {
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  /* padding-top: 0; */
  margin-bottom: 10px;
}

.Signup-basicDetails-boxes input[type="email"]:focus {
  border: 0;
}

.Signup-basicDetails-boxes input[type="number"]:focus {
  border: 0;
}

input#activateSolarRetail {
  cursor: pointer;
  transition: all 0.5s;
}

input#uveaaRetailterms {
  cursor: pointer;
  transition: all 0.5s;
}

.terms-text {
  color: rgb(21 67 176);
}

/*create job page css*/
.left-section {
  display: flex;
  width: 250px;
  flex-direction: column;
}

.left-panel {
  background: linear-gradient(180deg, #e1eaff 0%, rgba(255, 255, 255, 0) 100%);
  height: fit-content;
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 15%;
}

.container {
  width: auto;
  height: auto;
}

.dashboard-logo {
  /* padding: 10px; */
  /* height: 18vh; */
}

.left-part {
  display: flex;
  background: transparent;
  color: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 163px;
  height: 56px;
  margin: 0 15px;
  margin-bottom: 7px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  align-items: center;
  justify-content: left;
 
}

/* Hover state when it's not active */
.left-part:not(.active):hover {
  background: rgb(21 67 176);
  /* slightly transparent blue */
  color: white;
}

/* Active state */
.left-part.active {
  background: rgb(21 67 176);
  /* solid blue */
  color: white;
}

.left-img {
  margin: 10px 15px 10px 10px;
  width: 20%;
}

.left-text {
  margin: 18px 0px;
  font-size: 13px;
  font-weight: 400;
  /* width: 90%; */
  text-align: left;
}

.hide {
  display: none;
}

.left-img .default-image:hover {
  display: none;
}

.left-img .selected-image {
  display: none;
}

.left-part:hover .selected-image,
.left-part.active .selected-image {
  display: block;
}

.left-part:hover .default-image,
.left-part.active .default-image {
  display: none;
}

.img-border {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
}

.left-img {
  color: rgb(21 67 176);
}

.left-part .default-image {
  display: block;
  transition: all 0.5s;
}

.left-part .selected-image {
  display: none;
}

.left-part:hover .default-image {
  display: none;
}

.left-part:hover .selected-image {
  display: flex;
}

.left-part:active .default-image {
  display: none;
}

.left-part:active .selected-image {
  display: flex;
}

/*right part */
.main-jobpart {
  display: flex;
  flex-direction: column;
}

.text-blue {
  color: rgb(21 67 176);
  font-size: 20px;
  font-weight: 700;
  margin: 20px 20px;
  width: 60%;
}

.text-jobs {
  font-size: 14px;
  margin-left: 20px;
  font-weight: 400;
  color: rgba(44, 58, 100, 1);
  line-height: 13px;
}

.right-part {
  display: flex;
  /* justify-content: right; */
  /* margin: 0;
  padding: 0; */
  position: relative;
  gap: 2%;
}

button.btn-createjob {
  /* position: absolute;
  top: -55px;
  right: 210px; */
  border: none;
  outline: 0;
  color: rgb(21 67 176);
  background: rgba(255, 255, 255, 1);
  width: 270px;
  height: 34px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 50px;
  cursor: pointer;
  gap: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(21 67 176);
  &:hover {
    background: rgb(21 67 176);
    color: rgba(255, 255, 255, 1);
  }
}

.notification {
  position: relative;
}

.bell-icon {
  position: absolute;
  top: -46px;
  right: 150px;
}

.notification-text {
  position: relative;
}

.notify-round {
  position: absolute;
  top: -46px;
  right: 140px;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
}

.person-right {
  position: relative;
  /* right: 0; */
  width: 8%;
  margin-right: 30px;
}

.person-photo {
  position: relative;
  /* top: -53px; */
  right: 0%;
  vertical-align: middle;
  background-color: rgb(21 67 176);
  padding: 4px 2px;
  border-radius: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.search-container {
  width: 75% !important;
  min-width: 78%;
  min-height: 52px;
  margin: 15px 0px 15px 20px;
}
.input-search {
  width: 100%;
}
.input-search[type="text"] {
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  height: 52px;
  display: block;
  width: 100%;
  /*20px [ left & Right ] padding + 4px border [ left & Right ] */
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
  border: 0;
}

.search-icon {
  position: relative;
}

.search-img {
  position: absolute;
  top: -52px;
  right: 284px;
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
}

.btn-filter {
  position: relative;
}
.buttons-container {
  width: 50%;
  display: flex;
  gap: 2%;
  align-items: baseline;
  justify-content: right;
  padding-right: 20px;
}
.filter-text {
  /* position: absolute;
  top: -64px;
  right: 158px; */
  color: rgb(21 67 176);
  border: 1px solid rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 25px;
  border-radius: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 34px;
  &:hover {
    background: rgb(21 67 176);
    color: rgba(255, 255, 255, 1);
  }
}

.btn-clear {
  position: relative;
}

.clear-text {
  /* position: absolute;
  top: -64px;
  right: 44px; */
  color: rgb(21 67 176);
  border: 1px solid rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
  padding: 10px 25px;
  border-radius: 50px;
  display: flex;
  gap: 10px;
  justify-content: center;
  text-align: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
  width: 100%;
  height: 34px;
  &:hover {
    background: rgb(21 67 176);
    color: rgba(255, 255, 255, 1);
  }
}

.quick-filter {
  display: flex;
  margin: 15px 20px;
  gap: 10px;
}

.quick-filter span {
  color: rgba(65, 93, 165, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
}

.filter-job-btn {
  /* margin: 0 7px; */
  width: 167px;
  height: 33px;
  border: 1px solid rgba(90, 120, 190, 1);
  background: transparent;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(90, 120, 190, 1);
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.text-margin {
  margin-left: 7px;
}

.blue-text-job {
  color: rgba(90, 120, 190, 1);
  font-size: 14px;
  font-weight: 700;
}

.filter-job-btn:hover {
  background: rgba(44, 58, 100, 1);
  color: rgba(255, 255, 255, 1);
}

.filter-submit-btn {
  /* margin: 0 10px; */
  width: 204px;
  height: 33px;
  border: 1px solid rgba(90, 120, 190, 1);
  background: transparent;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(90, 120, 190, 1);
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.filter-submit-btn:hover {
  background: rgba(44, 58, 100, 1);
  color: rgba(255, 255, 255, 1);
}

.filter-solid-btn {
  margin: 0 7px;
  width: 158px;
  height: 33px;
  border: 1px solid rgba(90, 120, 190, 1);
  background: transparent;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(90, 120, 190, 1);
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.filter-solid-btn:hover {
  background: rgba(44, 58, 100, 1);
  color: rgba(255, 255, 255, 1);
}

.filter-two-btn {
  margin: 0 122px;
}

.ready-sell-btn {
  width: 180px;
  height: 33px;
  margin-right: 15px;
  border: 1px solid rgba(90, 120, 190, 1);
  background: transparent;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(90, 120, 190, 1);
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ready-sell-btn:hover {
  background: rgba(44, 58, 100, 1);
  color: rgba(255, 255, 255, 1);
}

.checking-btn {
  width: 246px;
  height: 33px;
  margin-right: 20px;
  border: 1px solid rgba(90, 120, 190, 1);
  background: transparent;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(90, 120, 190, 1);
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.checking-btn:hover {
  background: rgba(44, 58, 100, 1);
  color: rgba(255, 255, 255, 1);
}

.address-block {
  margin: 20px 20px;
  width: 359px;
  display: inline-grid;
}

.address-block-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  border-radius: 10px;
}

.left-img-block {
  padding: 20px;
}

.right-part-address {
  padding: 15px 0;
}

.user-img {
  display: flex;
  width: auto;
  height: 16px;
  margin-top: 7px;
}

.user-name {
  margin: 0;
  padding: 0;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  /* margin-left: 10px; */
  font-weight: 700;
  display: inline-flex;
  /* margin-bottom: 9px; */
}

.user-address {
  display: flex;
  /* justify-content: right; */
  font-size: 14px;
  font-weight: 700;
  /* margin-left: 30px; */
}

.user-text {
  width: 84% !important;
  color: rgba(44, 58, 100, 1);
  line-height: 17px;
  font-size: 14px;
  /* margin-left: 10px; */
  text-wrap: wrap;
}

.location-address {
  margin: 20px 0;
}

.commercial {
  margin: 20px 0;
}

.commercial-area {
  border-right: 2px solid rgba(174, 195, 255, 1);
  font-weight: 400;
  line-height: 13px;
  font-size: 14px;
  padding: 0 10px 0 0;
}

.comm-text {
  margin-left: 10px;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
}

.main-stc.text-stc {
  margin: 0 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 17%;
}

.text-stc {
  margin: 0 20px;
}

.stc-count {
  color: rgba(44, 58, 100, 1);
  background: rgba(192, 216, 251, 1);
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 50px; */
  font-weight: 700;
  line-height: 27px;
  font-size: 24px;
  margin: 0 10px;
}

.stc-text {
  color: rgba(44, 58, 100, 1);
  line-height: 28px;
  font-weight: bold;
  text-align: center;
  /* margin: 0 auto; */
  /* padding-top: 8%; */
  font-size: 12px !important;
}

.createdby {
  transform: translate(80px, -55px);
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
}

.ref-id {
  transform: translate(80px, -40px);
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
}

.system-text {
  margin: 0 0;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
}

.line-blue::after {
  content: "";
  border: 1px solid rgba(204, 217, 250, 1);
  width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(73px, -13px);
}

.system-all {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
}

.system-info {
  margin: 0 0 0 0;
  width: 40%;
  align-items: center;
}

.text-system {
  margin-left: 10px;
  padding: 1px 0;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.system-battery {
  display: flex;
  /* margin-left:5px; */
}

.battery-info {
  margin-left: 10px;
  display: flex;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  align-items: center;
}

.system-offgrid {
  display: flex;
  margin-left: 5px;
}

.offgrid-info {
  margin-left: 10px;
  display: flex;
  align-items: baseline;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.team-text {
  margin: 0 20px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
}

.blue-line::after {
  content: "";
  border: 1px solid rgba(204, 217, 250, 1);
  width: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(66px, -13px);
}

.team-name {
  display: flex;
  margin: 0 20px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  font-weight: 700;
}

.teaminfo-icon {
  display: inline-block;
  margin-left: 7px;
  padding: 2px 0;
  cursor: pointer;
}

.system-eng {
  margin: 0 11px;
}

.eng-info {
  display: flex;
}

.eng-info li {
  margin: 0 5px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

li.active {
  color: rgb(21 67 176);
  font-size: 14px;
  font-weight: 400;
  line-height: 27px;
}

.progress-dashboard {
  width: auto;
  height: 5px;
  margin: 7px 20px;
  border-radius: 50px;
  background: rgba(174, 195, 255, 1);
}

.progressdashboard-status {
  border-radius: 50px;
  display: inline-block;
  width: 173px;
  height: 5px;
  padding: 2px 1px;
  background: rgb(21 67 176);
  color: rgba(44, 58, 100, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 27px;
}

/*create job design css*/
.main-createjob {
  display: flex;
  flex-direction: column;
}

.right-part-job {
  display: flex;
  justify-content: right;
  margin: 0;
  padding: 0;
  position: relative;
}

.notification-main {
  position: relative;
}

.bell-img {
  position: absolute;
  top: -37px;
  right: 117px;
}

.notification-text {
  position: relative;
}

.round-blue {
  position: absolute;
  top: -40px;
  right: 108px;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
}

.person-img {
  position: absolute;
  top: -49px;
  right: 28px;
  vertical-align: middle;
}

.panel-info {
  margin: 10px 20px;
  display: inline-block;
  color: rgba(44, 58, 100, 1);
  font-size: 20px;
  font-weight: 700;
}

.address-bg {
  margin: 20px 0px;
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  /* width: 1130px; */
  border-radius: 10px;
}

.address-info {
  margin: 20px 20px 0 0;
  display: flex;
}

.address-input {
  width: 455px;
  height: 52px;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background: rgba(224, 237, 255, 1);
  outline: none;
  
}
.address-input::placeholder {
  color: grey;
  font-size: 400;
  
}

input.address-input {
  border: none;
  background: transparent;
}

.border-bottom {
  margin: 0 0px 0 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 1);
  width: 1093px;
  display: inline-block;
  opacity: 10%;
}

.address-text {
  display: inline-block;
  margin: 20px 15px;
  color: rgba(44, 58, 100, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}

.address-box {
  margin: 0 20px 20px 0;
}

.address-main-boxes {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.address-type {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}
.address-type::placeholder {
  color: grey;
  font-weight: 400;
}
.warranty-description-textarea {
  width: 100%;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
}

.warranty-description-textarea::placeholder{
  color:grey;
  font-weight: 400;

}
.unit-main-boxes {
  margin-left: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.unit-main-boxes > input {
  border: none;
  background: transparent;
}

.unit-no {
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
  width: 100%;
}
.unit-no::placeholder{
  font-weight: 400;
}
.street-type {
  width: 180px;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}
.street-type::placeholder {
 font-weight: 400;
}
.state-main-boxes {
  margin-right: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
}

.state-code {
  width: 180px;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;
}

.main-address-block {
  display: inline-block;
  margin: 0 10px;
}

.map-section {
  transform: translate(-1px, -5px);
  position: absolute;
  display: inline-block;
  margin: 0px 0 0 25px;
  width: 421px;
  height: 196px;
}

.main-installation {
  display: inline-block;
}

/*install*/
.install-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  margin: 20px 0px 0 0px;
  padding: 0px 0 20px 0px;
  border-radius: 10px;
  width: 99%;
}

.text-type {
  margin: 0 20px;
  color: rgba(44, 58, 100, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}

/* input[type="radio"] {

} */
.radio-margin {
  margin: 0 20px;
}

input#firstTime {
  margin: 0px 10px 0 20px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid rgb(21 67 176);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  vertical-align: middle;
}

input#additional {
  margin: 0px 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid rgb(21 67 176);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  vertical-align: middle;
}

input#extension {
  margin: 0px 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid rgb(21 67 176);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  vertical-align: middle;
}

input#replacement {
  margin: 0px 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1px solid rgb(21 67 176);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  vertical-align: middle;
}

.note {
  margin: 15px 0px 0 20px;
  display: inline-block;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 600;
  line-height: 13px;
}

.note-text {
  display: inline-block;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
}

.panel-bg {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 428px;
  height: 52px;
}

.panel-location {
  margin: 10px 20px;
}

.panel-location > span {
  padding: 10px 0;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  display: block;
}

.remark-bg {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 428px;
  height: 119px;
}

.panel-location.remark-bg {
  margin: 13px 0;
}

.panel-location.remark-bg {
  /* transform: translate(450px, -165px); */
}

.system-approved {
  margin: 20px 25px;
}

.system-main {
  display: flex;
  margin: 5px 0;
}

.mainsystem-text {
  margin: 0;
  padding: 0;
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

input.input-text {
  margin: 0 7px 0 0px;
  border: 1px solid rgb(21 67 176);
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.approved-govt {
  margin: 0 0 0 40px;
}

/*different postal address css*/
.postal-address {
  margin: 15px 0px;
}

.postal-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
}

.postal-address p {
  padding: 15px 10px;
  color: rgba(44, 58, 100, 1);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
  display: block;
}

.main-address-section {
  margin: 0 20px;
}

.street-space {
  margin-left: 20px;
}

.suburb-space {
  margin-left: 20px;
}

.installing-boxes {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 426px;
  height: 52px;
}

.installing-boxes > input {
  border: none;
  background: transparent;
}

.installing-type {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;

  overflow: auto;
}

.installing-type > span {
  display: inline-block;
}

.install-for {
  margin: 15px 0px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
}

.owner-type {
  margin: 15px 20px;
}

.owner-type > span {
  padding: 10px 10px;
  display: inline-block;
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
}

.owner-main {
  display: flex;
  margin: 10px 0;
}

li.main-text {
  margin: 0 15px 0 15px;
  vertical-align: middle;
  display: inline-block;
}

.residential-main > img {
  width: 78px;
  height: 50px;
}

.owner-residential > img {
  width: 47px;
  height: 49px;
}

/* .system-detail {
  margin: 20px 0px;
} */
.system-detail {
  display: flex;
  flex-direction: row;
  /* width: 160%; */
  justify-content: center;
  gap: 10px;
  margin: 20px 0px;
}
.details-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
}

.system-detail > p {
  padding: 15px 10px;
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 700;
  line-height: 17px;
}

.install-for {
  display: flex;
}

.install-bg-main {
  padding: 15px 15px;
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  border-radius: 10px;
}

.main-detail {
  margin: 12px 0px;
}

.detail-input {
  padding: 12px 15px;
  border-radius: 5px;
  border: none;
  width: 204px;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  /* color: #1543b0; */
  font-weight: 600;
  font-size: 14px;
  margin-right: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  
}
.detail-input::placeholder {
  color: grey;
  font-weight: 400;
}

.phone-space {
  margin-top: 0;
}

.gst-system {
  margin-top: 0;
  /* margin-left: 15px; */
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 500;
}

.relation-boxes {
  margin-right: 23px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px; 
}
 .relation-boxes::placeholder {
  color: grey;
  font-weight: 400;
}
.relation-boxes > input {
  border: none;
  background: transparent;
}

.relation-type {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 5px 0;

  overflow: auto;
}

.relation-type::placeholder {
  color: grey;
  font-weight: 400;
}

.relation-type > span {
  display: inline-block;
}

.grid-title {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 20px;
  line-height: 19px;
  margin: 20px 20px;
}

.grid-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 0 20px 0 20px;
}

.grid-connect {
  margin: 20px 20px;
}

.grid-connect > span {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.application-input {
  margin: 10px 20px 0 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 237px;
  height: 52px;
  line-height: 13px;
}

.application-input::placeholder{
  font-weight: 400;
}
.application-input > input {
  border: none;
  background: transparent;
}

.system-boxes {
  margin-left: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 439px;
  height: 52px;
}

.system-boxes > input {
  border: none;
  background: transparent;
}

.system-type {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;

  overflow: auto;
}

.national-input {
  margin: 0 20px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 300px;
  height: 52px;
  line-height: 13px;
}
.national-input::placeholder{
  font-weight: 400;
}

.national-input > input {
  border: none;
  background: transparent;
}

.meter-input {
  margin: 0 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}
.meter-input::placeholder{
  font-weight: 400;
}
.meter-input > input {
  border: none;
  background: transparent;
}

.title-system {
  margin: 20px 30px;
  color: rgba(44, 58, 100, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
}

.panel-main-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  /* margin: 0px 0px; */
  padding: 0px 15px;
}

.panel-information {
  margin: 20px 0 0 0;
}

.Model-boxes {
  margin: 0 16px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}

.brand-input {
  margin: 0 10px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 370px;
  height: 52px;
  line-height: 13px;
}
.brand-input::placeholder {
  color: grey;
  font-weight: 400;
}
.supplier-input {
  margin: 0 20px 0 4px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}

.quantity-input {
  margin: 15px 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 100px;
  height: 52px;
  line-height: 13px;
}
.quantity-input::placeholder{
  color: grey;
  font-weight: 400;
}
.quantity-text {
  margin: 15px 20px 0 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 130px;
  height: 52px;
  line-height: 13px;
}
.quantity-text::placeholder {
  color: grey;
  font-weight: 400;
}
.systemmain-input {
  margin: 15px 20px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}
.systemmain-input::placeholder {
  color: grey;
  font-weight: 400;
}
.finanacial-input {
  margin: 15px 0px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}
.finanacial-input::placeholder{
  color: grey;
  font-weight: 400;
}
.numberput-input {
  margin: 15px 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}
.numberput-input::placeholder {
  color: grey;
  font-weight: 400;
}
.warranty-text {
  margin-top: 24px;
}

.w-deatils {
  margin-top: 15px;
  margin-left: 20px;
}

.inverter-input {
  margin: 15px 10px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 262px;
  height: 52px;
  line-height: 13px;
}
.inverter-input::placeholder{
  color: grey;
  font-weight: 400;
}
.invertermodel-boxes {
  margin: 15px 10px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 330px;
  height: 52px;
  line-height: 13px;
}
.invertermodel-boxes::placeholder{
  color: grey;
  font-weight: 400;
}
.system-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 15px 20px 0 0;
}

.enter-details {
  padding: 20px 20px;
  font-size: 16px;
  line-height: 15px;
}

.enter-details > p {
  padding-top: 15px;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}

.details-main {
  padding-top: 15px;
}

.details-input {
  margin: 23px 20px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 121px;
  height: 52px;
  line-height: 13px;
}

.brandmain-input {
  margin: 23px 20px 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}

.mainmodel-boxes {
  margin: 17px 20px 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}

.circular-progress {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.circular-progress svg {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.progress-text {
  position: absolute;
  font-size: 1em;
  text-align: center;
}

.progressbar-input {
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  height: 52px;
  margin-left: 20px;
  margin-top: 23px;
  position: relative;

  width: 561px;
}

.progressmain-bar {
  /* background: rgba(76, 175, 80, 1); */
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: 30px;
  width: 354px;
  height: 32px;
  left: -550px;
}

.progress-text {
  color: rgba(255, 255, 255, 1);
  font-size: 19px;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  align-items: center;
}

.site-team-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 15px 20px 15px 0;
  padding: 20px 0px;
}

.site-text {
  color: rgba(44, 58, 100, 1);
  padding: 20px 20px;
}

.name-boxes {
  margin: 15px 20px 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  line-height: 13px;
}

.send-sms-btn {
  background: rgb(21 67 176);
  border-radius: 50px;
  width: 140px;
  height: 41px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.send-email-btn {
  background: rgb(21 67 176);
  border-radius: 50px;
  margin-left: 10px;
  width: 140px;
  height: 41px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.instructions-input {
  margin: 15px 0px 0 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 382px;
  line-height: 13px;
  height: 280px;
}
.instructions-input::placeholder{
  color:grey;
  font-weight: 400;
}
.upload-doc-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 15px 20px 15px 0;
  padding: 20px 15px;
}

.document-details {
  margin-top: 15px;
  margin-bottom: 15px;
}

.document-details > label {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
}

.upload-btn,
.download-btn,
.browse-btn {
  margin-left: 10px;
  background: rgb(21 67 176);
  border-radius: 50px;
  width: 140px;
  height: 41px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border: 0;
  outline: 0;
  cursor: pointer;
}

.text-design {
  margin-right: 72px;
}

.additional-bg {
  width: 864px;
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 0 0 15px 0;
  padding: 20px 15px;
}

.attributes-input {
  margin: 15px 20px 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 288px;
  height: 52px;
  line-height: 13px;
}
.attributes-input::placeholder{
  font-weight: 400;
  color: grey;
}
.retailer-input {
  margin: 15px 0 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 424px;
  height: 52px;
  line-height: 13px;
}
.retailer-input::placeholder{
  font-weight: 400;
  color: grey;
}
.system-any {
  margin-left: 0;
  font-size: 14px;
}
.current-input {
  margin: 0px 0 0 30px;
  padding: 17px 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 428px;
  height: 52px;
  line-height: 13px;
}

.upload {
  position: absolute;
  top: 10px;
  right: 14px;
}

.site-attribute-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  margin: 15px 20px 15px 20px;
  padding: 20px 15px;
}

.footer-bg {
  background: rgb(21 67 176);
  border-radius: 10px;
  width: 98%;
  height: 67px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
}

.main-footerbtn {
}

.submit-btn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  width: 107px;
  height: 41px;
  outline: 0;
  margin: 14px 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.submit-btn:hover {
  background: rgba(255, 255, 255, 1);
  color: rgb(21 67 176);
}

/* .view-btn
{
  background: transparent;
  border:1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  width: 251px;
  height: 41px;
  outline: 0;
  margin: 15px 25px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.view-btn:hover
{
background: rgba(255, 255, 255, 1);
color: rgb(21 67 176);
} */
/* responsive design css */
@media (max-width: 767px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }

  .psd-toggle {
    width: 334px;
  }

  .login-container {
    height: 100vh;
  }

  .logo {
    top: 30px;
  }

  .ForgetPassword-main-container {
    background-size:cover !important;
    height: 100vh !important;
  }
  .pas-form{
    width: 220px !important;
  }
  input.psd-toggle{
    width: 220px !important;
  }
  section.login-box.main-box {
    width: 297px;
    height: 470px;
  }
  .logo img {
   width: 130px;
   margin-bottom: 8%
  }
  .Login_Fgpwd_container {
    flex-direction: column;
  }
 .form-container input[type="text"] {
    width: 220px !important;
    height: 52px;
    align-items: center;
   
  }
  section.login-box.main-box {
    align-items: center;
  }
  .ForgetPassword_main-conatainer-sec1.main-box {
    width: 300px;

  }
  .ForgetPassword-Email-box {
    width: 254px;
  }
  .main-part-ratailer{
    width: 100% !important;
  }
  .main-jobpart{
    width: 90%;
    position: relative;
    float: right;
  }
  .text-blue{
    font-size: 12px;
  }
  .headerButtonsSection{
    justify-content: baseline;
    width: 40%;
  }
  .buttons-container {
    width: 80% !important;
    justify-content:center !important;
  }
  button.btn-createjob {
    width: 400px !important;
    height: 25px !important;
    font-size: 9px !important;
  }
  .sideBarMenu{
    width: 50%;
  }
  .Logo-image-sideMenu{
    width: 80% !important;
  }
  .STCMainDiv{
    /* flex-direction: column !important; */
  }
  .STCOuterDiv{
    flex-direction: column !important;
    height: 11%;
    gap:20px !important;
    align-items: flex-start !important;
  }
  .PriceDataText {
    font-size: 12px !important;
    margin-right: 0px;
  }
  .STCPriceSection{
    width: 40% !important;
    flex-direction: column;
  }
  .priceTextLive {
    font-size: 12px !important;
  }
  .TabsDiv{
    height: 40% !important;
    justify-content: left !important;
    width: 100%;
    padding-left: 6%;
  }
  .input-search[type="text"] {
    width: 85%;
    font-size: 10px;
  }
 .search-container {
  min-width: 100%;
  }
  .search-filter-section{
    flex-wrap: wrap;
  }
  .reportsButtonDiv{
    position: absolute;
    right: -20px;
    top:-36px;

  }
  .dashboard-part{
    flex-wrap: wrap;
    /* flex-direction: column-reverse; */

  }
  .DashboardCardFirstSectionRow{
    flex-wrap: wrap;
  }
  .system-all{
    flex-direction: row !important;
    gap:2px;
  }
  .system-info{
    display: contents;
  }
  .dash-text{
    width: 32% !important;
  }
  /* .DetailsOfOwner{
    width: 50% !important;
  } */
  /* .main-stc.text-stc{
    flex-direction: row;
    gap:20px;
  } */
  .stc-text{
    text-align: left;
    overflow-wrap: break-word;
  }
  .stc-count{
    font-size: 12px;
    width: 80px;
    margin: 0;
  }
  .DashboardFirstSection{
    width: 100%;
  }
  .JobActions{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .edit-job {
    width: 102px !important;
    font-weight: 600;
    font-size: 12px !important;
    overflow-wrap: break-word;
    line-height: 13px !important;
  }
  .detail-input {
    width:270px !important
  }
  .CreatJobDetailsForm{
    width: 100% !important;
  }
  .quick-filter{
  flex-wrap: wrap;
  margin: 15px 6px;
  gap:3px;
  justify-content: center;
  }
  .quick-filter span {
    margin-right: 2%;
  }
  .modal-content-createJob{
    width:92%
  }

  .modalHeader{
    gap:176px !important
  }
  .filter-submit-btn{
    width:100px;
  }
  .filter-job-btn {
    width: 100px;
  }
  .InformationFormDiv{
    width: 35% !important;
    overflow-x: scroll;
  }
  .panel-main-bg{
    padding:0
  }
  .main-detail{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap:10px;
    align-items: left;
  }
  .detailContainerForm{
    flex-direction: column !important;
  }
  .address-input{
    width: 90%;
  }
  .address-postcode{
    margin-top: 2%;
    margin-left: 0 !important;
  }
  .address-input{
    width: 80%;
  }
  .Signup-autoAdress-container{
    flex-wrap: wrap;
  }
  .installationTypeForm{
    flex-wrap: wrap;
  }
  .border-bottom{
    width: 100%;
  }
  .InstallationDetailsGridSection{
    flex-wrap: wrap;
    gap:20px;
  }
  .search-panel {
    margin-left: 0px !important;
    width: 380px !important;
   
  }
  .InfoAndDeleteContainer{
    flex-wrap: wrap;
    margin-top: 4%;
  }
  .serialno-addinput{
    width:150px !important;
  }
  .SubSectionEditJob{
    flex-direction: column;
  }
  .subSectionInsideDiv{
    flex-direction: column; 
  }
  .warranty-description-textarea{
    width: 280px !important;
  }
}

@media (max-width: 460px) {
  form.form-container {
    width: auto;
    display: flex;
    margin-right: 0;
  }

  .form-container input[type="text"] {
    width: 450px;
    display: block;
    text-align: start;
  }

  /* .pas-form {
    width: 390px;
  } */

  .psd-toggle {
    width: 400px;
  }


  .ForgetPassword-main-container {
    background-size: 100% 100%;
    height: 600px;
  }
  .main-stc.text-stc{
    flex-direction: row;
    gap:10px;
    height: 18%;
  }
  .DetailsOfOwner{
    width: 50% !important;
  }
  .dash-text{
    width: 50% !important;
  }
  .DetailsOfOwner{
    width: 50% !important;
  }
  .system-all {
    flex-direction: row !important;
    
  }
  .system-info{
    width: 100%;
    display: inline-block;
  }
  /* .detail-input {
    width:220px !important
  } */
  .filter-submit-btn{
    width:120px;
  }
}

/*team dashboard css*/
.notify-text {
  position: relative;
}

.notifybell-img {
  position: absolute;
  top: -59px;
  right: 120px;
}

.notifyround-blue {
  position: absolute;
  top: -59px;
  right: 112px;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
}

.notifyperson-img {
  position: absolute;
  top: -70px;
  right: 26px;
  vertical-align: middle;
}

.team-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  border-radius: 10px;
  margin: 15px 10px 0 20px;
  padding: 20px 20px;
}

.team-btn {
  position: relative;
  display: flex;
  gap: 20px;
  padding: 10px;
}

.btn-add {
  background: rgb(21 67 176);
  width: 87px;
  height: 41px;
  border-radius: 50px;
  /* position: absolute; */
  top: 25px;
  right: 140px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.btn-invite {
  background: rgb(21 67 176);
  width: 87px;
  height: 41px;
  border-radius: 50px;
  /* position: absolute; */
  top: 25px;
  right: 40px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.team-main-bg {
  background: rgba(224, 237, 255, 1);
  width: 104%;
  height: 55px;
  /* margin: 0 20px; */
}

.team-main {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
  width: 191px;
}

.team-role {
  width: 215px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.team-email {
  width: 228px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
  margin: 15px -12px;
}

.team-mobile {
  width: 200px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.team-actions {
  width: 110px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 20px;
}

.team-data-main {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
  width: 20%;
  text-align: left;
}

.team-data-role {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-email {
  width:20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-mobile {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}

.team-data-actions {
  width: 20%;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 0px;
}

.team-details {
  display: flex;
  margin: 15px 15px;
  /* width: 191px; */
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
}

.name-details {
  margin-right: 178px;
  text-align: center;
  width: 50px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
}

.add-action,
.view-action,
.notview-action {
  border: 0;
  padding: 0px 0;
  background: transparent;
  margin-right: 8px;
  cursor: pointer;
}

.main-team-bg {
  background: rgba(243, 248, 255, 1);
  height: 55px;
}

.name-space {
  padding: 14px 0;
}

/*invite popup design css*/

/* responsive design css */
/* @media (max-width: 767px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 30px;
  }


  .psd-toggle {
    width: 334px;
  }

  .logo {
    top: 30px;
  }

  .ForgetPassword-main-container {
    background-size: 100% 100%;
    height: 600px;
  }
} */

/* @media (max-width: 460px) {
  form.form-container {
    width: auto;
    display: flex;
    align-items: flex-start;
    margin-right: 0;
  }

  .form-container input[type="text"] {
    width: 400px;
    display: block;
  }

  .psd-toggle {
    width: 400px;
  }

  .ForgetPassword-main-container {
    background-size: 100% 100%;
    height: 600px;
  }
  .main-price {
    width: -webkit-fill-available;
  }
  .live-price {
    width: -webkit-fill-available;
  }
  .main-part-ratailer {
    max-width: -webkit-fill-available;
  }
} */

/* Invite Model Starts */

.invite-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.invite-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: auto;
  height: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.invite-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.invite-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.popupbottom-border {
  color: rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: 100%;
  /* margin: 0 -20px; */
  padding: 0;
}

.invite-model-close-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  background: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 23px;
  height: 23px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
}

.popup {
  background: rgb(207, 207, 207);
  width: 470px;
  height: 330px;
  margin: 0 auto;
}

.main-popup {
  margin: 20px 20px;
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  position: relative;
}

.popupbottomadd-border {
  border-bottom: 1px solid rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: -webkit-fill-available;
}

.popup-email {
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  line-height: 13px;
  width: 328px;
  height: 52px;
  border: 0;
  outline: 0;
}

.popup-phone {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  line-height: 13px;
  width: 328px;
  height: 52px;
  border: 0;
  outline: 0;
}

.popup-checkbox {
  margin-top: 10px;
  margin-left: 10px;
}

input.popup-checkbox {
  border-color: #506eec;
  border: 1px solid rgb(21 67 176);
  width: 16px;
  height: 16px;
  vertical-align: sub;
  cursor: pointer;
}

span.check-text {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 400;
  display: inline-flex;
  margin-left: 10px;
  vertical-align: middle;
}

input.input-field {
  padding: 0 20px;
}

.btn-cancel {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 41px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  outline: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.button-invite {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 41px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  margin: 15px;
  outline: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.footer-popupbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.button-invite:hover {
  background: rgb(93, 112, 157);
  color: rgba(255, 255, 255, 1);
}

.btn-cancel:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

/* Invite Model Ends */

/* Add Model Starts */

.add-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-model-content {
  background-color: #fff;
  padding: 20px 20px;
  border-radius: 0;
  width: auto;
  max-width: 1170px;
  max-height: 80vh;
  overflow-y: auto;
}

.add-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.add-model-close-btn {
  background: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 23px;
  height: 23px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.add-model-form {
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(4, 1fr); */
  gap: 10px;
  margin-top: 18px;
}

.add-name {
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-email {
  /* margin-left: 20px; */
  padding: 0 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-phone {
  /* margin-left: 20px; */
  padding: 0 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  width: 265px;
  height: 52px;
}

.add-personphoto {
  margin-left: 20px;
  padding: 23px 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  height: 52px;
}

input.add-personphoto {
  width: 274px;
}

.photo-upload {
  position: relative;
  top: 0;
  right: 0;
}

.photo-main {
  position: absolute;
  right: 15px;
  top: 10px;
}

.mapadd-section {
  transform: translate(-1px, 19px);
  position: relative;
  display: inline-block;
  margin: 0px 0 0 25px;
  width: 421px;
  height: 196px;
}

.add-cce {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 200px;
  height: 52px;
}

.what-servesection {
  display: flex;
  flex-direction: row;
  gap: 30px;
  /* margin-top: 20px; */
  font-size: 14px;
  font-weight: 400;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  padding: 0 12px;
  border: 0;
  outline: 0;
  width: 70% !important;
  height: 64px;
  overflow: auto;
  margin-bottom: 20px;
}

.adddetail-input {
  padding: 12px 15px;
  border-radius: 5px;
  border: none;
  width: 200px;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  margin-right: 20px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
}

.add-ccephoto {
  margin-top: 20px;
  font-size: 10px;
  font-weight: 400;
  padding: 23px 12px;
  background: rgba(224, 237, 255, 1);
  border-radius: 5px;
  border: 0;
  outline: 0;
  height: 52px;
}

.ccephoto-size {
  /* padding-top: 20px; */
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  opacity: 50%;
  font-weight: 400;
}

input.add-ccephoto {
  width: 200px;
}

.addphoto-main {
  position: absolute;
  right: 30px;
  top: 30px;
}

.add-model-form input,
.add-model-form select,
.add-model-form textarea {
  border: 1px solid #ccc;
  border: 0;
}

.add-model-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.add-model-actions button {
  padding: 10px 20px;
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid rgb(21 67 176);
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  margin-left: 10px;
  transition: all 0.5s ease-in-out;
}

.add-model-actions button:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

button.cancel-hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.submit-hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.add-model-actions button[type="button"] {
  background-color: transparent;
  padding: 10px 20px;
  border-radius: 50px;
  border: 1px solid rgb(21 67 176);
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 400;
}

label.label-text {
  position: absolute;
  left: 30px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-cce {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-eleid {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.label-design {
  position: absolute;
  top: 22px;
  left: 14px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.addmap-section {
  transform: translate(-1px, 20px);
  display: inline-block;
  margin: 0px 0 0 25px;
  width: 421px;
  height: 196px;
}

#Addinstaller-PersonPhoto {
  border: none;
  color: #000;
  font-family: Poppins;
  font-size: var(--font-size-12);
  width: auto;
}

/* Add Model Ends */
/* Delete Model Starts */
.delete-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: 500px;
  height: 140px;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); */
}

/* Delete Model Ends */

/*add view page responsive css*/

@media (min-width: 360px) and (max-width: 410px) {
  .responsive-model {
    display: block !important;
  }

  .add-email {
    margin-left: 0;
    padding: 0 12px 0;
    background: rgba(224, 237, 255, 1);
    border-radius: 5px;
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-name {
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-phone {
    margin-left: 0;
    width: -webkit-fill-available;
    height: 52px;
  }

  #Addinstaller-PersonPhoto {
    margin-left: 0;
    width: -webkit-fill-available;
  }

  input.add-email {
    margin-top: 20px;
  }

  input.add-phone {
    margin-top: 20px;
  }

  input#Addinstaller-PersonPhoto {
    margin-top: 20px;
  }

  label.label-text {
    position: absolute;
    left: 14px;
    top: 23px;
  }

  .photo-main {
    position: absolute;
    right: 12px;
    top: 32px;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
  }

  .unit-main-boxes {
    margin-top: 20px;
    width: -webkit-fill-available;
    height: 52px;
    margin-left: 0;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
    margin-top: 20px;
  }

  .street-type {
    width: 300px;
  }

  .state-main-boxes {
    width: 100%;
    height: 52px;
  }

  .state-code {
    width: 280px;
  }

  .popup-checkbox {
    margin: 0 8px 0 8px;
  }

  .add-cce {
    width: 100%;
    height: 52px;
  }

  .adddetail-input {
    width: 100%;
    height: 52px;
  }

  input.add-ccephoto {
    width: auto;
  }

  .addphoto-main {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  .what-servesection {
    width: auto;
    height: 221px;
    padding: 15px 8px;
  }

  .responsive-installer {
    display: block !important;
  }
  /* .resetpassword-container{
    width: 80% !important;
    max-width: 100% !important;
    } */
  /* input.psd-toggle-reset{
      width: 295px;
    } */
    section.login-box.main-box {
      width: 297px;
      height: 446px;
      gap: 2px; 
    }
}

@media (max-width: 767px) {
  .responsive-model {
    display: block !important;
  }

  .add-email {
    margin-left: 0;
    padding: 0 12px 0;
    background: rgba(224, 237, 255, 1);
    border-radius: 5px;
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-name {
    width: -webkit-fill-available;
    height: 52px;
  }

  .add-phone {
    margin-left: 0;
    width: -webkit-fill-available;
    height: 52px;
  }

  #Addinstaller-PersonPhoto {
    margin-left: 0;
    width: -webkit-fill-available;
  }

  input.add-email {
    margin-top: 20px;
  }

  input.add-phone {
    margin-top: 20px;
  }

  input#Addinstaller-PersonPhoto {
    margin-top: 20px;
  }

  label.label-text {
    position: absolute;
    left: 14px;
    top: 23px;
  }

  .photo-main {
    position: absolute;
    right: 12px;
    top: 32px;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
  }

  .unit-main-boxes {
    margin-top: 20px;
    width: -webkit-fill-available;
    height: 52px;
    margin-left: 0;
  }

  .address-main-boxes {
    width: 100%;
    height: 52px;
    margin-top: 20px;
  }

  .street-type {
    width: 300px;
  }

  .state-main-boxes {
    width: 100%;
    height: 52px;
  }

  .state-code {
    width: 280px;
  }

  .popup-checkbox {
    margin: 0 8px 0 8px;
  }

  .add-cce {
    width: 100%;
    height: 52px;
  }

  .adddetail-input {
    width: 100%;
    height: 52px;
  }

  input.add-ccephoto {
    width: auto;
  }

  .addphoto-main {
    position: absolute;
    right: 10px;
    top: 30px;
  }

  .what-servesection {
    width: auto;
    height: 221px;
    padding: 15px 8px;
  }

  .responsive-installer {
    display: block !important;
  }
}

.profileview-model-backdrop {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.profileview-model-content {
  background-color: rgba(255, 255, 255, 1);
  padding: 20px 20px;
  border-radius: 0;
  width: 865px;
  height: 630px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.profilevie-model-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.viewbottom-border {
  border-bottom: 1px solid rgba(44, 58, 100, 1);
  display: inline-flex;
  opacity: 30%;
  width: 865px;
  margin: 15px -20px;
  padding: 0;
}

.profile-model-header > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.profile-model-header,
span {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 12px;
  line-height: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
}

.left-profile-photo {
  margin: 20px 0;
}

.right-profile-details {
  margin: 20px 20px;
}

.profile-addresss {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.phone-receiver {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.email-detail {
  display: inline-flex;
  vertical-align: super;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
}

.addmain-details {
  /* margin: 0 60px 0 0; */
  /* margin: 0 auto; */
}

.view-main-details {
  margin: 10px 0;
  display: flex;
  gap: 4px;
  font-size: 12px;
}

.serve-detail {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
}

/*new dashboard design css*/
.live-price {
  /* margin: 10px 20px; */
  display: flex;
  justify-content: center;
}

/* .priceblue-progress {
  background: rgb(21 67 176);
  width: 100%;
  height: 72px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
} */

.text-white {
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding: 17px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.price-data li {
  margin-left: 40px;
  padding: 15px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-filter {
  margin: 20px 20px;
}

.information-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  padding: 20px 20px;
  border-radius: 10px;
}

.dashboard-information {
  margin: 15px 20px;
}

.inform-text {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
}

.create-date {
  display: inline-block;
}

.show-date {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 12px;
  margin-left: 7px;
  line-height: 21px;
}

.create-install {
  display: block;
}

.show-install {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  line-height: 21px;
}

.create-watt {
  display: block;
  font-size: 13px;
}

.show-watt {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  line-height: 21px;
}

.dash-address {
  /* margin: 0 10px 0 0px; */
  display: block;
}

.show-Residential {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  line-height: 21px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licenceImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
}

.licenceImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* This will ensure that the image's aspect ratio is preserved */
}

.editRetailer-buttons {
  display: flex;
  gap: 25px;
  width: 130px;
  height: 41px;
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
}

/*new dashboard design css*/
.live-price {
  margin: 10px 10px;
}

.main-price {
  background: rgba(204, 217, 250, 1);
  border: 1px solid rgba(192, 216, 251, 1);
  width: 95%;
  height: 72px;
  border-radius: 15px;
}

.priceblue-progress {
  background: rgb(21 67 176);
  width: 10%;
  height: 71px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.text-white {
  color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  padding: 17px 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
}

.dashboard-filter {
  margin: 20px 20px;
}

.information-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  padding: 20px 20px;
  border-radius: 10px;
}

.dashboard-information {
  margin: 15px 20px;
}

.inform-text {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline;
}

.create-date {
  display: grid;
}

.show-date {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 12px;
  margin-left: 0;
  line-height: 21px;
}

.create-install {
  display: block;
}

.show-install {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 0;
  line-height: 21px;
  display: flex;
}

.show-watt {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  line-height: 21px;
}

.create-Residential {
  display: block;
  margin: 5px 0;
}

.show-Residential {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  margin-left: 7px;
  line-height: 21px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.licenceImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  overflow: hidden;
}

.licenceImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  /* This will ensure that the image's aspect ratio is preserved */
}

.edit-retailer-profile {
  width: 130px;
  height: 34px;
  border-radius: 50px;
  cursor: pointer;
  border: none;
  font-size: 16px;
  text-align: center;
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  /* margin: 0 auto; */
  display: inline-block;
  line-height: 15.31px;
  margin-top: 10px;
  background-color: rgb(21 67 176);
  text-decoration: none;
  /* position: absolute;
  right: 40px;
  bottom: 80px; */
}

.editRetailer-buttons {
  display: flex;
  gap: 25px;
  width: 130px;
  height: 41px;
  border-radius: 50px;
  color: #0000 !important;
}

.live-price {
  /* background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%); */
  width: 100%;
  border-radius: 10px;
  /* margin: 15px 20px 0 20px; */
  /* padding: 20px 20px; */
}

.live-price-table {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  align-items: center;
  gap: 20px;
  background: #e0edff;
  width: 1090px;
  height: 55px;
  margin: 0 20px;
}

/*createpvjob page doc upload chose file center align code*/
input.current-input.doc-file {
  padding: 17px 10px;
}

/*login page responsive css*/
@media (max-width: 360px) {
  .user-form {
    position: relative;
    width: auto;
    height: auto;
  }

  /* .pas-form {
    width: 86%;
    display: inline-block;
  } */

  .form-container a {
    margin-left: 80px;
  }

  section.login-box.main-box {
    width: 410px;
    margin: 0 auto;
  }
}

@media  (max-width: 320px) {
  .user-form {
    position: relative;
    width: auto;
    height: auto;
  }

  /* .pas-form {
    width: 86%;
    display: inline-block;
  } */

  .form-container a {
    margin-left: 80px;
  }

  section.login-box.main-box {
    width: 410px;
    margin: 0 auto;
  }
}

/*new dashboard system info popup design css start*/
.popup-system {
  margin-top: 10px;
}

.popupmain-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottom-border-popup {
  border-bottom: 1px solid rgba(174, 195, 255, 1);
  margin-top: 10px;
}

.popup-height {
  height: 490px;
}

.panel-brand {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  display: block;
  margin-left: 0;
}

.popupsystem-all {
  display: flex;
  /* margin: 15px 0px; */
  height: 44%;
}

.popupsystem-img {
  display: flex;
}

li.popup-person {
  margin-left: 10px;
  vertical-align: super;
}

/*team popup design css*/
.team-view {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin-left: 0;
}

.team-mobile {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 14px;
  display: block;
  margin-left: 0;
}

.teampopupsystem-all {
  display: flex;
}

.teampopupsystem-img {
  display: flex;
}

.teampopup-height {
  height: 450px;
}

.team-display-all {
  display: flex;
}

/*action popup design css*/
.actionpopup-height {
  width: 642px;
  height: 440px;
}

.popupblue-text {
  display: flex;
  justify-content: space-between;
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
}

.action-data {
  margin: 20px 0;
}

.main-square {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(21 67 176);
  margin: 20px 0;
  width: 280px;
  height: 83px;
  border: 1px solid rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 10px 0px rgba(0, 67, 229, 0.2);
  display: flex;
  font-weight: 700;
  transition: all 0.5s ease-in-out;
  /* justify-content: space-between; */
}

.main-square:hover {
  background-color: #0043e5;
  color: #ffffff;
}

.text-center {
  text-align: center;
  margin: 0 auto;
}

.tooltip {
  visibility: hidden;
  width: 180px;
  background-color: rgb(21 67 176);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  position: absolute;
  z-index: 1;
  /* left: 50%; */
  /* margin-left: -100px; */
  display: flex;
  justify-content: center;
  top: -55px;
  font-size: 12px;
  left: -100px;
}

.tooltip::after {
  content: "";
  position: absolute;
  left: 50%;
  /* margin-left: -5px; */
  border-width: 5px;
  border-style: solid;
  border-color: transparent !important;
  text-align: center;
}

.show-tooltip {
  visibility: visible;
}

/*createpvjob upload dicument section css*/
.doc-upload {
  margin-top: 20px;
}

.onsite-jobpart {
  display: flex;
  flex-direction: column;
}

.onsite-part {
  margin: 0;
  padding: 0;
  position: relative;
}

.onsitedata {
  position: relative;
}

.onsitebell-icon {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  transform: translate(690px, 6px);
}

.onsitedata-text {
  position: relative;
}

.onsite-round {
  position: absolute;
  top: 20px;
  right: 0;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  transform: translate(724px, 6px);
}

.onsite-right {
  position: relative;
}

.onsite-photo {
  position: absolute;
  top: 3px;
  right: 0;
  vertical-align: middle;
  transform: translate(801px, 10px);
}

.text-onsite {
  font-size: 14px;
  margin-left: 20px;
  font-weight: 400;
  color: rgba(44, 58, 100, 1);
  line-height: 0px;
}

.onsite-blue {
  color: rgb(21 67 176);
  font-size: 20px;
  font-weight: 700;
  margin: 10px 20px;
}

/*onsite data button design css*/
.onsite-panelbutton {
  margin: 20px 20px;
}

.data-chekpanel {
  margin-bottom: 10px;
  border: 1px solid rgba(44, 58, 100, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  width: 230px;
  height: 49px;
  background-color: transparent;
  cursor: pointer;
  gap: 10px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
}

.data-chekpanel.active {
  border: 1px solid rgb(21 67 176);
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.data-chekpanel:hover {
  border: 1px solid rgb(21 67 176);
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.dash-border-bottom {
  border-bottom: 1px solid rgba(174, 195, 255, 1);
  margin: 25px 0;
}

/*view onsite data check in panel selfi images design css */
.checkin-panel {
  margin: 10px 0;
}

.checkin-panel > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 18px;
}

.setup-selfi {
  display: flex;
}

.set-selfi {
  padding: 10px 0;
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
}

.setup-selfi li {
  margin-right: 22px;
}

/*panel series table format css*/
.panelserial-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 884px;
  border-radius: 10px;
  margin: 15px 20px 0 0px;
  padding: 20px 17px;
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}

.paneladd-btn {
  position: relative;
  /* margin-left: 2%; */
}

.panelserial-btn {
  background: rgb(21 67 176);
  width: 87px;
  height: 41px;
  border-radius: 50px;
  position: absolute;
  top: 25px;
  right: 140px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.serial-section {
  background: rgba(224, 237, 255, 1);
  width: 864px;
  height: 55px;
  margin: 0 0px;
}

.serial-main-bg {
  background: rgba(224, 237, 255, 1);
  width: 864px;
  height: 55px;
  margin: 0 20px;
}

.serial-main {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0;
  line-height: 27px;
  width: 174px;
}

.serial-role {
  width: 153px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.serial-email {
  width: 228px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
  margin: 15px -12px;
}

.serial-mobile {
  width: 200px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.serial-actions {
  width: 70px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 20px;
}

.panel-data {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 30px 54px 0 20px;
}

.panelserial-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.serial-maindata {
  width: 147px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 0 47px 0 0;
  line-height: 27px;
  border-bottom: 1px solid rgba(44, 58, 100, 1);
}

.serial-maindatadelete {
  width: 72px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.button-center {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cancel-btn {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 10px;
}

.cancel-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.save-btn {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.save-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

/*on click add button open popup design css*/
.onsitedate-popup-height {
  height: 435px;
}

.text-panel {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}

.onsitedata-model-close-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  background: rgba(44, 58, 100, 1);
  border-radius: 50px;
  width: 23px;
  height: 23px;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.panel-bottom-border {
  border: 1px solid rgba(44, 58, 100, 1);
  opacity: 30%;
  margin: 17px 0 0 -20px;
  padding: 0;
  width: 500px;
}

.onsitedata-input {
  margin: 20px 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 460px;
  height: 52px;
  line-height: 13px;
}

.onsitedatabrand-input {
  margin: 15px 0 15px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 460px;
  height: 52px;
  line-height: 13px;
}

.onsitspv-input {
  margin: 15px 20px 0px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 460px;
  height: 52px;
  line-height: 13px;
}

.onsite-boxes {
  margin: 0px 20px 0px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 460px;
  height: 52px;
  line-height: 13px;
}

.onsitebtn-cancel {
  margin-right: 10px;
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 41px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  outline: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.onsitebtn-cancel:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.onsitebtn-save {
  margin-left: 10px;
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  color: rgb(21 67 176);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 34px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  border-radius: 50px;
  outline: 0;
  margin: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.onsitebtn-save:hover {
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

button.onsitebtn-save {
  margin-top: 20px;
  margin-left: 10px;
}

button.onsitebtn-cancel {
  margin-top: 20px;
  margin-left: 10px;
}

/*inverter serial design page css*/
.inverter-serial-main {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0;
  line-height: 27px;
  width: 247px;
}

.inverter-serial-date {
  width: 210px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 20px;
  line-height: 27px;
}

.invreter-serial-actions {
  width: 70px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 20px;
}

.inverterserial-maindata {
  width: 198px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 0 47px 0 0;
  line-height: 27px;
  border-bottom: 1px solid rgba(44, 58, 100, 1);
}

.inverterpopup-serial {
  margin-top: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  line-height: 13px;
  width: 458px;
  height: 52px;
  border: 0;
  outline: 0;
}

.inverterserial-popup-height {
  height: 330px;
}

/*battery details page css*/
.onsitebattery-input {
  margin: 20px 20px 15px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 460px;
  height: 52px;
  line-height: 13px;
}

.battery-popup-height {
  height: 378px;
}

/*view onsite data photo page design css*/

.panel-roofbg {
  margin-bottom: 12px;
  margin-top: 20px;
  background-color: rgba(224, 237, 255, 1);
  width: 887px;
  height: 52px;
}

.text-roof {
  padding: 13px 20px;
  display: flex;
  align-items: center;
}

.btn-addphoto {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  width: 166px;
  height: 44px;
  position: absolute;
  top: 5px;
  right: 25px;
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
}

.plussign {
  margin-right: 10px;
}

.set-install-1 {
  padding: 10px 0;
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  overflow-wrap: break-word;
  width:180px
}

.photoupload-input {
  margin: 15px 0 0 0px;
  padding: 17px 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 428px;
  height: 52px;
  line-height: 13px;
}

.photoupload {
  position: relative;
  top: 10px;
  right: 60px;
  transform: translate(221px, -51px);
}

.photoupload-popup-height {
  height: 370px;
}

.delete-model-content.photoupload-popup-height {
  width: 471px;
}

.addphoto-bottom-border {
  border: 1px solid rgba(44, 58, 100, 1);
  opacity: 30%;
  margin: 17px 0 0 -20px;
  padding: 0;
  width: 471px;
}

span.file-text {
  display: inline-flex;
  padding: 12px 0;
  font-size: 14px;
  font-weight: 400;
  color: rgba(44, 58, 100, 1);
  line-height: 13px;
  opacity: 50%;
}

.addphoto-title {
  margin: 10px 0px 15px 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 428px;
  height: 52px;
  line-height: 13px;
}

.inverter-install-text {
  padding: 0px 10px;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  opacity: 50%;
}

/*commissioning Date design page css */
.text-date {
  font-size: 16px;
  line-height: 27px;
}

.selfie-photo {
  margin-left: 25px;
  margin-top: 30px;
}

.btn-photoupload {
  background: rgb(21 67 176);
  width: 120px;
  height: 41px;
  border-radius: 50px;
  position: absolute;
  top: 70px;
  right: 65px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

span.selfie-text {
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  display: block;
  color: rgba(0, 0, 0, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
}

/*signature design page css*/
.set-signature {
  display: block;
  margin: 0 auto;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 400;
  padding-top: 15px;
}

.person-name {
  color: rgba(0, 0, 0, 1);
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 13px;
  line-height: 27px;
}

.set-designerphoto {
  display: block;
  margin: 0 auto;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
  font-weight: 400;
  padding-top: 15px;
}

.person-name {
  color: rgba(0, 0, 0, 1);
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  line-height: 27px;
}

.btn-ownersignature {
  background-color: rgb(21 67 176);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 204px;
  height: 50px;
  border-radius: 50px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}

.btn-uploadsignature {
  background-color: rgb(21 67 176);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 145px;
  height: 30px;
  /* position: absolute; */
  top: 232px;
  right: 165px;
  border-radius: 50px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.blank-img {
  position: relative;
}

.ownersignature-popup-height {
  width: 471px;
  height: 248px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0px;
  padding-top: 15px;
  position: relative;
  top: 204px;
  right: 86px;
  left: 105px;
}

.input-container input {
  padding: 10px 15px;
  padding-top: 25px;
  border-radius: 5px;
  border: none;
  width: 284px;
  height: 52px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
  font-size: 14px;
}

.input-container label {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.input-container input:focus + label,
.input-container input.has-content + label {
  top: -20px;
  left: 10px;
  font-size: 12px;
  color: #333;
  padding: 0 5px;
  background: #fff;
}

.input-container input:focus,
.input-container input.has-content {
  padding-top: 25px;
}

.input-container input:focus + label,
.input-container input:not(:placeholder-shown) + label {
  top: -20px;
  /* Adjust these values to fit your design */
}

.selected {
  background-color: rgb(21 67 176);
  color: white;
}

.uploadsignature-popup-height {
  width: 471px;
  height: 300px;
}

.doc-text {
  color: #000000;
  opacity: 50%;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
}

.btn-adddocument {
  background: rgb(21 67 176);
  width: 172px;
  height: 41px;
  border-radius: 50px;
  position: absolute;
  top: 70px;
  right: 30px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.blue-bg {
  background-color: #e0edff;
  width: 205px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-fileimg {
  position: relative;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.set-form {
  width: 187px;
  color: #2c3a64;
  line-height: 15px;
  margin-top: 20px;
}

.background-light {
  background-color: rgba(0, 0, 0, 0.1);
}

.background-lightbg {
  background-color: rgba(0, 0, 0, 0.1);
}

.background-viewbg {
  background-color: rgba(0, 0, 0, 0.1);
}

/*view stc details page design css*/
.btn-mainchange {
  background: rgb(21 67 176);
  width: 100px;
  height: 41px;
  border-radius: 50px;
  position: absolute;
  top: 84px;
  right: 148px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
}

.btn-mainadd {
  background: rgb(21 67 176);
  width: 100px;
  height: 41px;
  border-radius: 50px;
  position: absolute;
  top: 82px;
  right: 37px;
  margin-left: 10px;
  border: 0;
  outline: 0;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  cursor: pointer;
  opacity: 40%;
}

.view-solar-home {
  margin-top: 26px;
}

.bottom-border-black {
  border: 1px solid rgba(44, 58, 100, 1);
  opacity: 30%;
  margin: 17px 0 0 -20px;
  padding: 0;
  width: 706px;
  margin-bottom: 20px;
}

.addaddress-popup-height {
  height: 430px;
}

/*system owner design css*/
.system-ownerbg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  width: 864px;
}
.owner-space {
  padding: 18px;
}
/*floating label css*/
.form-md {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}
input#form_name1 {
  position: relative;
  display: block;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 14px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
}
input#form_name2 {
  position: relative;
  display: block;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 14px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
}
input#form_name1 > label {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}

label.email-name-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px !important;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
  padding: 0 4px 8px 20px;
}
input#form_name2 {
  position: relative;
  display: block;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
}

label.input-mobileno {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.input-phoneno {
  position: relative;
  display: block;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
}
label.phoneno-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 0 20px;
  height: 14px;
  line-height: 14px;
  font-size: 14px !important;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}

input:hover,
input:focus {
  border: 0;
  outline: 0;
}

input:valid + label,
input:focus + label {
  top: -17px;
  left: 0px;
  outline: 0;
  font-size: 13px;
  font-weight: 400;
}
.gl-form-asterisk {
  background-color: transparent;
  /* color: #e32b2b; */
  padding: 0;
  padding-left: 3px;
}
.gl-form-asterisk:after {
  /* content: "*"; */
}
.input-firstname {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
  width: 190px;
  height: 52px;
  font-weight: 400;
}
input.form-control.input-lastname {
  position: relative;
  display: block;
  width: 260px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}
label.last-name-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px 20px;
  height: 14px;
  line-height: 14px;
  font-size: 14px !important;
  font-weight: 400;
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.email-name-input {
  width: 190px;
  margin: 0 10px 0 14px;
  font-size: 12px !important;
  font-weight: 400;
}
input#form_name1 {
  width: 260px;
}

.last-name-input {
  margin: 0 10px 0 14px;
  font-size: 12px !important;
  font-weight: 400;
}
.input-mobileno {
  width: 260px;
}
.input-phoneno {
  margin-left: 20px;
  width: 260px;
}
.gst-no {
  margin-left: 20px;
  margin-right: 7px;
}
.textfor-gst {
  margin-left: 20px;
}
input#form_name3 {
  position: relative;
  display: block;
  width: 260px;

  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0 auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 0;
}
label.firstname-field {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}

/* ScheduleInstallation css */

.schedule-installation-container {
  font-family: "Arial", sans-serif;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}

.title {
  color: #333;
  margin-bottom: 20px;
}

.calendars-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.calendars-container .react-datepicker-wrapper {
  margin-right: 10px;
  /* Adds margin to the right of the first calendar */
}

.calendars-container .react-datepicker-wrapper:last-child {
  margin-right: 0;
  /* Removes the margin from the right of the last calendar */
}

/* Custom Styles for react-datepicker */
.react-datepicker {
  border: none;
}

.react-datepicker__month-container {
  background: #fff;
}

.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: none;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  top: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  margin: 0.166rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__time-list-item--selected {
  background-color: #2a64db;
  color: #fff;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.actions .cancel-button,
.actions .save-button {
  padding: 10px 20px;
  margin-right: 5px;
  border: none;
  border-radius: 20px;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
}

.cancel-button {
  background-color: #6c757d;
}

.save-button {
  background-color: #007bff;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.save-button:hover {
  background-color: #0056b3;
}
/*grid connection page design css*/
.main-group {
  margin-bottom: 20px;
  margin-top: 5px;
}
h2.grid-connection-title {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}
.grid-connection-form {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  width: 864px;
  padding: 20px;
}
.text-connected > label {
  color: rgba(44, 58, 100, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
}
#systemType {
  position: relative;
  display: block;
  width: 190px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 4px 4px 4px 9px;
  height: 52px;
  outline: none !important;
  font-size: 14px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 0;
}
.system-type-input {
  position: relative;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.input-national {
  position: relative;
  display: block;
  width: 400px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}
.nmi-name-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px 20px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.input-meter {
  position: relative;
  display: block;
  width: 190px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}
.meter-number-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px 20px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.input-distributor {
  position: relative;
  display: block;
  width: 190px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-right: 0;
}
.Distributor-name-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px 0;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.input-application {
  position: relative;
  display: block;
  width: 400px;
  border: 0;
  border-radius: 5px;
  background-color: rgba(224, 237, 255, 1);
  margin: 0px auto;
  padding: 12px 4px 4px 14px;
  height: 52px;
  outline: none !important;
  font-size: 16px;
  color: rgba(44, 58, 100, 1);
  transition: all 0.2s ease-in-out;
  margin-left: 20px;
}
.application-number-input {
  position: absolute;
  top: 20px;
  left: 12px;
  text-align: left;
  display: inline-block;
  padding: 0 4px 8px 20px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: #fff;
  color: rgba(44, 58, 100, 1);
  background-color: transparent;
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
.form-actions {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid-main {
  display: flex;
  margin: 10px 0;
}
.main-section-add {
  display: flex;
  justify-content: space-between;
  gap: 250px;
}

/*system Details page design css*/
.details-text > h3 {
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
  margin-top: 15px;
}

.System-detail-bg {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  width: 864px;
  border-radius: 10px;
}
.panel-text {
  padding: 20px 20px;
  margin: 0 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
}
.brand-info {
  margin: 0 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 190px;
  height: 52px;
  line-height: 13px;
}
.model-info {
  margin: 0 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 190px;
  height: 52px;
  line-height: 13px;
}
.quantity-info {
  margin: 15px 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 200px;
  height: 52px;
  line-height: 13px;
}
.qty-input {
  margin: 15px 20px 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 190px;
  height: 52px;
  line-height: 13px;
}
.add-text {
  display: flex;
  margin-bottom: 20px;
}
.gst-info {
  margin-right: 25px;
}
.stand-solar {
  position: relative;
}
.stand-solar-img {
  position: absolute;
  right: 10px;
  bottom: -10px;
}
.financial-text {
  margin: 20px 0;
  width: 820px;
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}
input.brand-info {
  margin-top: 20px;
}
.warranty-textarea {
  width: 100%;
  background-color: transparent;
  color: rgba(44, 58, 100, 1);
  outline: 0;
  border: none;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 0;
  overflow: auto;
  width: 820px;
}
.warranty-area {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  /* width: 428px; */
  height: 108px;
}
.inverter-bg {
  margin: 20px 0 0 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  width: 864px;
  border-radius: 10px;
}
.inverter-text {
  padding: 20px 20px;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
}
.inverter-main {
  position: relative;
  z-index: 0;
}
.inverter-img-right {
  position: absolute;
  position: absolute;
  right: 10px;
  bottom: -64px;
  z-index: 1;
}
.model-space {
  margin-top: 20px;
}
.battery-bg {
  margin: 20px 0 0 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #f3f8ff 97.76%);
  width: 864px;
  border-radius: 10px;
}
.battery-text {
  padding: 20px 20px;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
}
.form-button {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-form {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 20px;
}

.cancel-form:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.save-form {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 20px;
}

.save-form:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

/*Installation Team page design css*/
.team-details > h3 {
  color: rgb(21 67 176);
  font-size: 18px;
  font-weight: 700;
  line-height: 17px;
}

.design-square {
  margin-top: 23px;
  background-color: rgba(234, 243, 255, 1);
  width: 220px;
  height: 163px;
  border-radius: 5px;
  margin-right: 20px;
}
.design-invoice {
  margin-top: 23px;
  background-color: rgba(234, 243, 255, 1);
  width: 220px;
  height: 163px;
  border-radius: 5px;
  margin-right: 20px;
  position: relative;
  z-index: 0;
}
.upload-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 22px 0;
}
.upload-pdf {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 34px 0;
}
.text-light {
  color: rgba(0, 0, 0, 1);
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  opacity: 50%;
}
/* .browse-button
{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: rgb(21 67 176);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  border-left: rgba(192, 216, 251, 1);
  padding-left: 10px;
} */
.browse-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: rgb(21 67 176);
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  border-right: rgba(192, 216, 251, 1);
  cursor: pointer;
}
.upload-fileimg {
  position: absolute;
  top: 92px;
  right: 10px;
}
.uploadimg {
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
}
.design-text {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  margin: 0 auto;
  text-align: center;
  display: block;
  padding-top: 30px;
}
.design-custom {
  color: rgba(44, 58, 100, 1);
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  margin: 0 auto;
  text-align: center;
  display: block;
  padding-top: 15px;
}
.form-button {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.cancelbtn-form {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 40px;
}

.cancelbtn-form:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}

.savebtn-form {
  border: 1px solid rgb(21 67 176);
  background-color: transparent;
  border-radius: 50px;
  width: 100px;
  height: 41px;
  color: rgb(21 67 176);
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-top: 40px;
}

.savebtn-form:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
/*additional attribute*/
.additional {
  margin: 20px 0;
  color: rgb(21 67 176);
  font-weight: 700;
  font-size: 18px;
  line-height: 17px;
}
.attributes-text {
  margin: 15px 20px 0 0px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 402px;
  height: 52px;
  line-height: 13px;
}
.retailer-text {
  margin: 15px 0 0 0;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 432px;
  height: 52px;
  line-height: 13px;
}
.currentbill-input {
  margin: 15px 0 0 0;
  padding: 17px 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 302px;
  height: 52px;
  line-height: 13px;
}

.paneladdmain-btn {
  position: relative;
  right: -246px;
  bottom: 25px;
}
/*manage folder page css*/
.panel-ser {
  padding: 20px 20px 20px 10px;
  color: rgba(44, 58, 100, 1);
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
}
.panel-photobg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: auto;
  margin: 0 20px 0 0;
  border-radius: 10px;
}
.panel-photomain {
  padding: 20px 0 0 0;
  position: relative;
  z-index: 0;
  margin: 0 15px 0 0;
}
.main-uploadbtn {
  border: 0;
  outline: 0;
  background-color: transparent;
  position: absolute;
  right: 5px;
  bottom: 57px;
  cursor: pointer;
}
.panel-size {
  /* padding: 0 0 0 20px; */
  margin: 0 auto;
  text-align: left;
}

.panel-img {
  width: 261px;
  height: 223px;
  margin: 0 0 0 20px;
  cursor: pointer;
}

.download-button {
  position: relative;
  top: 58px;
  right: -183px;
  border: none;
}

.pdf-icon {
  position: relative;
  top: 33px;
  right: -42px;
}
/*required documents page css*/

.design-document {
  margin-top: 23px;
  background-color: rgba(234, 243, 255, 1);
  width: 261px;
  height: 223px;
  border-radius: 10px;
  margin-left: 20px;
  position: relative;
  z-index: 0;
}

.download-document {
  position: relative;
  top: 155px;
  right: -103px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.doc-google {
  position: absolute;
  top: 48px;
  z-index: 1;
}
/*upload files page design css */
.upload-files {
  margin-left: 20px;
}
.subtract {
  vertical-align: middle;
  margin: 0 -14px;
}
/*important actions page design css*/
.process-text {
  width: 466px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
}
.process-status {
  width: 484px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  margin: 15px 0px;
  line-height: 27px;
  margin: 15px -12px;
}
.process-actions {
  width: 160px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  line-height: 27px;
  margin: 15px 0px;
}
.important-main {
  padding: 14px 20px;
}
/*site inspection page design css*/
.assign-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 96%;
  margin: 20px 20px;
  border-radius: 10px;
}
.start-time {
  background-color: rgba(224, 237, 255, 1);
  width: 303px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
  margin-bottom: 20px;
}
.end-time {
  background-color: rgba(224, 237, 255, 1);
  width: 303px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
}
.select-input {
  background-color: rgba(224, 237, 255, 1);
  width: 366px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
}
.site-bellicon {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  transform: translate(754px, 6px);
}

.inspectionsite-round {
  position: absolute;
  top: 20px;
  right: -63px;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  transform: translate(724px, 6px);
}
.inspectionsite-photo {
  position: absolute;
  top: 3px;
  right: -77px;
  vertical-align: middle;
  transform: translate(801px, 10px);
}
.select-inspector {
  font-size: 14px;
  display: inline-block;
}
.select-main {
  background-color: rgba(224, 237, 255, 1);
  width: 366px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
}
.submit-main-btn {
  border: 1px solid rgb(21 67 176);
  border-radius: 50px;
  width: 100px;
  height: 41px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
  color: rgb(21 67 176);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.submit-main-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
.cancel-main-btn {
  border: 1px solid rgb(21 67 176);
  border-radius: 50px;
  width: 100px;
  height: 41px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
  color: rgb(21 67 176);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.cancel-main-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
/*view site Inspection page design css*/
.customer-text {
  margin: 5px 20px;
  /* padding: 20px 0; */
}
.customer-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.customer-input {
  background-color: rgba(224, 237, 255, 1);
  width: 202px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
  margin: 20px 20px;
}
.retailer-main.upload-files {
  margin-left: 0;
}
input.customer-input {
  margin: 0 12px;
}
.approx-input {
  background-color: rgba(224, 237, 255, 1);
  width: 229px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0px;
}
.pitch-input {
  background-color: rgba(224, 237, 255, 1);
  width: 303px;
  height: 52px;
  border: 0;
  outline: 0;
  border-radius: 5px;
  padding: 0 10px;
  margin: 20px 20px;
}
.roof-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.info-batterybg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.site-inverterbg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.swith-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.solution-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.final-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}
.additional-bg {
  background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%);
  width: 1130px;
  border-radius: 10px;
}

.back-main-btn {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  width: 100px;
  height: 41px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
.back-main-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
.cancel-main-btn {
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  width: 100px;
  height: 41px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
}
.cancel-main-btn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
.site-footerbg {
  width: 100%;
  height: 67px;
  background-color: rgb(21 67 176);
  border-radius: 10px;
  box-shadow: rgba(0, 67, 229, 0.25);
  margin-bottom: 60px;
}
/*design team and action details side by side text page css*/
.team-text {
  margin: 0 0 0 20px;
}
/*assign site inspection page for cancel button hover css change*/
.cancelbtn {
  border: 1px solid rgb(21 67 176);
  border-radius: 50px;
  width: 100px;
  height: 41px;
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  background-color: transparent;
  color: rgb(21 67 176);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.cancelbtn:hover {
  background-color: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
}
/*new design for label all pages*/
.text-surname {
  position: relative;
  z-index: 0;
}
.label-surname {
  position: absolute;
  top: -61px;
  right: -94px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-email {
  position: relative;
  z-index: 0;
}
.label-email {
  position: absolute;
  top: -61px;
  right: -65px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-role {
  position: relative;
  z-index: 0;
}
.label-role {
  position: absolute;
  top: -61px;
  right: -57px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-username {
  position: relative;
  z-index: 0;
}
.label-username {
  /* position: absolute; */
  top: 0px;
  /* left: 3px; */
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-password {
  position: relative;
  z-index: 0;
}
.label-password {
  position: absolute;
  bottom: 0;
  left: 3px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}

.text-confirm {
  position: relative;
  z-index: 0;
}
.label-confirm {
  position: absolute;
  bottom: 0;
  left: 3px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-abn {
  position: relative;
  z-index: 0;
}
.label-abn {
  position: absolute;
  bottom: -10px;
  left: 3px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-company {
  position: relative;
  z-index: 0;
}
.label-company {
  position: absolute;
  top: -22px;
  left: 21px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-phoneno {
  position: relative;
  z-index: 0;
}
.label-phoneno {
  position: absolute;
  top: -22px;
  left: 23px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-unitno {
  position: relative;
  z-index: 0;
}
.label-unitno {
  position: absolute;
  top: -22px;
  left: 0;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
}
.text-streetno {
  position: relative;
  z-index: 0;
}
.label-streetno {
  position: absolute;
  top: -22px;
  right: -8px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}

.text-streetname {
  position: relative;
  z-index: 0;
}
.label-streetname {
  position: absolute;
  top: -28px;
  left: -9px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-streettype {
  position: relative;
  z-index: 0;
}
.label-streettype {
  position: absolute;
  top: -28px;
  left: -9px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-suburb {
  position: relative;
  z-index: 0;
}
.label-suburb {
  position: absolute;
  top: -28px;
  left: -9px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-state {
  position: relative;
  z-index: 0;
}
.label-state {
  position: absolute;
  top: -28px;
  left: 0px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-postcode {
  position: relative;
  z-index: 0;
}
.label-postcode {
  position: absolute;
  top: -28px;
  left: 0px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-bankname {
  position: relative;
  z-index: 0;
}
.label-bankname {
  position: absolute;
  top: -10px;
  left: 0px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-accountname {
  position: relative;
  z-index: 0;
}
.label-accountname {
  position: absolute;
  top: -48px;
  left: 22px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-bsbno {
  position: relative;
  z-index: 0;
}
.label-bsbno {
  position: absolute;
  top: -48px;
  left: 22px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-accountNo {
  position: relative;
  z-index: 0;
}
.label-accountNo {
  position: absolute;
  top: -48px;
  left: 22px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.text-streetname {
  position: relative;
  z-index: 0;
}
.label-streetname {
  position: absolute;
  top: -28px;
  left: -9px;
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
  font-weight: 400;
  width: max-content;
}
.Signup-Adress-boxes {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 0 20px 0px 0; */
}
/*login page label desgin*/
.form-md {
  width: 100%;
  text-align: center;
  /* margin: 32px auto 32px auto;  */
}
input#user_name1 {
  width: 374px;
  height: 52px;
  border-radius: 5px;
  border: transparent;
  display: block;
  padding-left: 50px;
  background: rgba(224, 237, 255, 1);
  margin: 0;
  color: rgba(44, 58, 100, 1);
}

input#user_name2 {
  width: 374px;
  height: 52px;
  border-radius: 5px;
  border: transparent;
  display: block;
  padding-left: 50px;
  background: rgba(224, 237, 255, 1);
  margin: 0;
  color: rgba(44, 58, 100, 1);
}
.form-md .form-group {
  margin-left: auto;
  margin-right: auto;
}
.form-group {
  margin-top: 20px;
  position: relative;
}
input#user_name1:hover,
input#user_name1:focus {
  border: 1px solid rgba(224, 237, 255, 1);
}
input#user_name1:valid + .text-usermain,
input#user_name1:focus + .text-usermain {
  top: -15px;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
}
input#user_name2:hover,
input#user_name2:focus {
  border: 1px solid rgba(224, 237, 255, 1);
}
input#user_name2:valid + .text-usermain,
input#user_name2:focus + .text-usermain {
  top: -15px;
  color: rgba(44, 58, 100, 1);
  font-weight: 400;
}
label.text-usermain {
  position: absolute;
  top: 20px;
  left: 40px;
  text-align: left;
  display: inline-block;
  padding: 0 4px;
  height: 14px;
  line-height: 14px;
  font-size: 14px;
  font-weight: 400;
  background: transparent;
  color: rgba(44, 58, 100, 1);
  margin: 0px auto;
  cursor: text;
  transition: all 0.15s ease-in-out;
}
/* .gl-form-asterisk {
  background-color: inherit;
  color: #e32b2b;
  padding: 0;
  padding-left: 3px;
 
}
.gl-form-asterisk:after {
  content: "*";
} */
.forgot-text {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  vertical-align: middle;
}

.modal-overlay-createJob {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content-createJob {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
/*dashboard design page css stsrt here*/
.main-part-ratailer {
  width: 86%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  float: right;
}
.dash-text {
  width: 15%;
}
.mobile-text {
  margin: 50px 0px 20px;
}

.userdash-text {
  margin-top: 15px;
}

.edit-job {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 122px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  line-height: 27px;
  font-size: 15px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.park-job {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 125px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  /* line-height: 27px; */
  font-size: 15px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  margin-top: 15px;
}
.request-doc {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 125px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  line-height: 27px;
  font-size: 13px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  margin-top: 15px;
}

.edit-stc {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 128px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  line-height: 27px;
  font-size: 13px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}

.certificate-text {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 153px !important;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  /* line-height: 27px; */
  font-size: 14px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.text-no {
  display: flex;
  justify-content: center;
  gap: 8px;
  /* margin: 20px 10px; */
  font-size: 11px;
  color: rgb(21 67 176);
  border: 1px solid rgb(21 67 176);
  padding: 1px 25px;
  border-radius: 5px;
}
.number-text {
  display: flex;
  justify-content: center;
  margin: 0 10px;
  font-size: 14px;
  color: rgb(21 67 176);
  border: 1px solid rgb(21 67 176);
  padding: 3px 10px;
  border-radius: 5px;
}
.site-form {
  color: rgba(255, 255, 255, 1);
  margin-top: 15px;
  background: rgb(21 67 176);
  width: 130px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 600;
  /* line-height: 27px; */
  font-size: 14.4px;
  outline: 0;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
}
.house-img {
  position: relative;
}
.house-no {
  position: relative;
  top: -62px;
  right: 5px;
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
}
.tick-mark {
  position: relative;
  top: -81px;
  right: -25px;
  background: rgba(75, 174, 79, 1);
  color: rgba(255, 255, 255, 1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tick-right {
  position: relative;
  top: -61px;
  right: -26px;
  background: rgba(75, 174, 79, 1);
  color: rgba(255, 255, 255, 1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tick-panel {
  position: relative;
  top: -61px;
  right: -24px;
  background: rgba(75, 174, 79, 1);
  color: rgba(255, 255, 255, 1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tick-action {
  position: relative;
  top: -61px;
  right: -24px;
  background: rgba(75, 174, 79, 1);
  color: rgba(255, 255, 255, 1);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 700;
  padding: 2px 4px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.teampopup-all {
  display: flex;
  /* margin: -20px 10px 10px 10px; */
}
.text-notes {
  display: flex;
  justify-content: flex-start;
  margin: 10px 15px;
  font-size: 14px;
  color: rgb(21 67 176);
  border: 1px solid rgb(21 67 176);
  padding: 3px 15px;
  border-radius: 5px;
}
.dashboard-part {
  display: flex;
  gap: 8px;
  /* justify-content: space-between; */
}
@media (max-width: 1500px) {
  .input-search {
    width: 95%;
  }
}
/*new design dashboard css*/
.gst-space {
  display: none;
  margin-left: 20px;
  width: 205px;
  height: 52px;
}
.input-same {
  margin: 0 7px 0 0px;
  border: 1px solid rgb(21 67 176);
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.main-checkbox {
  margin: 0 15px 0 15px;
  font-weight: 600;
}
.checkbox-same {
  margin: 0 0 0 10px;
  font-weight: 600;
}
.address-postcode {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 202px;
  height: 52px;
  margin-left: 10px;
}
.connection-boxes {
  margin-left: 25px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 209px;
  height: 52px;
}
.search-panel {
  margin-left: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 420px;
  height: 52px;
  line-height: 13px;
}
.search-battery {
  margin-left: 20px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 420px;
  height: 52px;
  line-height: 13px;
}
.plus-add {
  /* display: flex;s */
  /* align-items: center; */
  /* justify-content: flex-end; */
  background-color: transparent;
  /* position: absolute; */
  /* top: 30px; */
  /* right:20px; */
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
  align-items: center;
}
.plus-inverter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  position: absolute;
  top: 85px;
  right: 20px;
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
}
.job-panel {
  margin: 15px 20px 0 12px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  /* background: rgba(224, 237, 255, 1); */
  color: rgba(44, 58, 100, 1);
  text-align: left;
  /* width: 422px; */
  height: 52px;
  line-height: 13px;
}
.main-text {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clipboard-img {
  width: 20px;
  height: 20px;
}
.clipboard-copy {
  margin-top: 10px;
  width: 20px;
  height: 20px;
}
.clipboard-battery {
  margin-top: 20px;
  margin-left: 20px;
  width: 20px;
  height: 20px;
}

.serialno-addinput {
  margin: 10px 0px 0 15px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 300px;
  height: 52px;
  line-height: 13px;
}
.serialno-addinput::placeholder{
  color: grey;
  font-weight: 400;
}
.view-details {
  margin-left: 15px;
  margin-bottom: 10px;
  background: rgb(21 67 176);
  color: rgba(255, 255, 255, 1);
  border: none;
  outline: 0;
  width: 100px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.text-selection {
  margin-left: 15px;
}
.first-selfiimg {
  margin-top: 20px;
}
.set-date {
  text-align: center;
  display: flex;
  justify-content: center;
}
.meter-input {
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  display: inline-flex;
  border-radius: 5px;
  border: 0;
  background: rgba(224, 237, 255, 1);
  color: rgba(44, 58, 100, 1);
  text-align: left;
  width: 288px;
  height: 52px;
  line-height: 13px;
  margin-top: 3%;
}
.plusadd-serials {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  width: 166px;
  height: 44px;
  position: absolute;
  top: 7px;
  right: 40px;
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
}
.plusadd-documents {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  width: 166px;
  height: 44px;
  position: absolute;
  top: 7px;
  right: -873px;
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
}
.btn-partial {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  width: 200px;
  height: 41px;
  outline: 0;
  margin: 14px 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.btn-partial:hover {
  background: rgba(255, 255, 255, 1);
  color: rgb(21 67 176);
}
.submit-mainbtn {
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 1);
  border-radius: 50px;
  color: rgba(255, 255, 255, 1);
  width: 107px;
  height: 41px;
  outline: 0;
  margin: 14px 15px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  position: relative;
  /* right:-300px; */
  float: right;
}
.submit-mainbtn:hover {
  background: rgba(255, 255, 255, 1);
  color: rgb(21 67 176);
}
.plusadd-signature {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  width: 166px;
  height: 44px;
  position: absolute;
  top: -40px;
  right: 40px;
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
}
/*create job page include required documents css*/
.text-requireddoc {
  position: relative;
  z-index: 0;
}
.label-requireddoc {
  position: absolute;
  top: -45px;
  right: -91px;
  color: rgba(44, 58, 100, 1);
  font-size: 12px;
  font-weight: 400;
}
.label-maindoc {
  width: 260px;
  left: 3px;
}
@media (min-width: 1400px) and (max-width: 1680px) {
  .main-part-ratailer {
    padding-left:18px !important;
    width: 87% !important;
    float: right;
  }
  .left-panel{
    width:14vw
  }
  .left-part{
    width:180px
  }
}
.search-filter-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  /* gap:2%; */
  align-items: center;
}

.STCPriceSection {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 50%;
  background: linear-gradient(60deg, #e1eaff 80%, rgba(255, 255, 255, 0) 100%);
  padding: 12px;
  border-radius: 15px;
  margin-left: 20px;
}
.PriceDataText {
  color: rgba(44, 58, 100, 1);
  font-size: 15px;
  font-weight: 500;
  margin-right: 12px;
}

.price-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding:10px */
}
.priceTextLive {
  font-size: 20px;
}
.EditJobSection {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}
.SubSectionEditJob {
  /* justify-content: center; */
  display: flex;
  /* width: 'auto'; */
  padding: 0 20px;
}
.CreatJobDetailsForm {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  width: 86%;
  position: relative;
  /* overflow-y: scroll; */
  /* left:260px */
  float: right;
}
.sectionTitleText {
  font-size: 20px;
}
.subSectionInstallationteam {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
.InformationFormDiv {
  width: 98%;
  margin-left: 1%;
}
.CreateJobContainer {
  width: 100%;
}
.notes-input {
  width: 330px;
  height: 160px;
  border: none;
}
.btn-space {
  /* width: 40%; */
  display: flex;
  justify-content: flex-end;
}

/* .partial-Btn-space{
  width: 60%;
} */

.agreementSectionRegister {
  /* height: 10%; */
  padding: 1% 0;
}
.inputDetailsSection {
  /* padding:8px 0; */
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.labelTextFont {
  font-size: 14px;
  color: #696969;
}
.labelTextFirst {
  padding-bottom: 10px;
}
.inputBoxandLabelDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}
.panelAndAddButton {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}
.images-titleSectionPanel {
  width: 30%;
}
.ImageUploadSectionsPVJobs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
}
.DetailsOfOwner {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 30%;
}
.iconAndDetail {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  text-wrap: wrap;
}
.TeamsTableHeading {
  display: flex;
  padding-left: 1%;
}
.TeamDetailsRowData {
  display: flex;
  /* flex-wrap: wrap; */
  gap:10px;
  text-align: center;
  padding-left: 1%;
}
.traderDashButtons {
  color: rgba(255, 255, 255, 1);
  background: rgb(21 67 176);
  width: 130px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-weight: 500;
  /* line-height: 27px; */
  font-size: 14px;
  outline: 0;
  padding: 2%;
  cursor: pointer;
  border: 1px solid rgba(224, 237, 255, 1);
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  /* margin-top: 15px; */

  &:disabled {
    background: #c9c7c7;
    color: #000000;
  }
}
.Signup-heading-box-container {
  display: flex;
  flex-direction: row;
  gap: 95px;
  align-items: center;
  padding-bottom: 33px;
}
.page-modal-open {
  overflow: hidden;
}
.PopupBoxStyle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.page-modal-close {
  overflow: scroll;
}
.delete-field-button {
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: flex-end; */
  background-color: transparent;
  /* position: absolute; */
  /* top: 30px; */
  /* right:20px; */
  border: 0;
  outline: 0;
  color: rgb(21 67 176);
  font-size: 15px;
  font-weight: 700;
  line-height: 15px;
  cursor: pointer;
  align-items: center;
  padding-right: 2px;
}
.InfoAndDeleteContainer {
  display: flex;
  align-items: flex-end;
  gap: 0px;
  flex-wrap: wrap;
}
.Logo-image-sideMenu {
  width: 100%;
  padding: 8%;
}
.live-Price-main-section {
  display: flex;
  flex-direction: column;
  width: 85vw;
  /* margin-left: 17%; */
}
.buttonBox {
  display: flex;
  gap: 10px;
}
.MainOuterContainer {
  height: 100vh;
  display: flex;
  flex-direction: row;
  width: 100vw;
  overflow-x: hidden;
}
.sideBarMenu {
  height: 82vh;
}

.left-accountsection {
  display: flex;
  align-items: center;
  gap: 4px;
}

.help-section-dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 80vh;
  margin: 50px;
}
.RedirectButton {
  border: 1px solid #000;
  border-radius: 50px;
  font-size: 2rem;
  fill: #000;
  cursor: pointer;
}
.RedirectButtonBox {
  width: 8%;
  text-align: end;
  padding-right: 1%;
}

.ProfileContainerHeaderpart {
  display: flex;
  align-items: flex-end;
  width: 18%;
  justify-content: space-around;
  /* flex-direction: ; */
}

.Label-text-Login {
  color: rgba(44, 58, 100, 1);
  font-size: 14px;
}
.Owner-type-selection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 25px;
  width: 60%;
}
.Owner-type-selection li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.radio-ul {
  display: flex;
  gap: 10px;
}

.buttonContainer {
  justify-content: center !important;
  margin-top: 4%;
}

.createJobButton {
  background-color: rgb(21 67 176) !important;
  color: #fff !important;
  &:hover {
    background: #fff !important;
    color: rgb(21 67 176) !important;
  }
}
.invite-model-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.addphoto-main-Cancel {
  position: absolute;
  right: 40px;
  top: 10px;
}
.emailSection {
  width: 86%;
  text-wrap: wrap;
  align-items: baseline;
}

.email-id-owner {
  width: 100% !important;
  text-wrap: wrap;
  font-size: 14px;
  overflow-wrap: break-word;
  color: rgba(44, 58, 100, 1);
  line-height: 17px;
}

.searchBox_teams {
  width: 58%;
  min-width: 58%;
  /* min-height: 52px; */
  margin: 15px 0px 15px 0px;
}

.TemsTableHeadingContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  /* background: linear-gradient(180deg, #f3f8ff 0%, rgba(255, 255, 255, 0) 100%); */
}
.connectButton {
  /* background: rgb(21 67 176); */
  width: 77px;
  height: 30px;
  border-radius: 50px;
  /* position: absolute; */
  color: #000;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    background: rgb(21 67 176);
    color: #fff;
  }
}
.siteInspectionSection {
  display: flex;
  gap: 30px;
  margin-left: 20px;
  flex-wrap: wrap;
}
.siteInspectionColumn {
  flex-direction: column !important;
  gap: 8px !important;
}
.marginForSection {
  margin-top: 15px;
  margin-bottom: 25px;
}

.assign-site-inspection-main-class {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.assign-site-inspection-sub-class {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.CERPage-main-bg {
  background: rgba(224, 237, 255, 1);
  width: 99%;
  height: 55px;
  /* margin: 0 20px; */
}
.PanelDataDropDownHeader{
  display: flex;
  justify-content: space-between;
   padding:0 10px
 
}
.PanelDataDropDown{
  display: flex;
  gap: 100;
  padding: 0 10px;
}
.tableBodyDropdown :hover{
    background-color:#CADDE1;
   
}
@media (max-height: 690px) {
  .login-container {
    overflow-y: scroll;
    justify-content: start;
  }
  .bg-overlay {
    height: 120%;
  }
}

.resetPasswordPage{
display: flex;
/* flex-direction: column !important; */
gap:20px;
justify-content: center;
align-items: center;
height: 60%;
width: 400px;
}

@media (min-width:320px)and(max-width: 690px) {
  .login-container {
    overflow: scroll;
    justify-content: start;
  }
  .bg-overlay {
    height: 120%;
  }
  /* .resetpassword-container{
    width: 80% !important;
    max-width: 100% !important;
    } */
  input.psd-toggle-reset{
      width: 100%;
    }
}
.headerButtonsSection{
  display: flex;
  width: 50%;
  justify-content: end;
  gap: 3%;
  align-items: center;
}